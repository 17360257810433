import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  colorValidation = {
    'custom_button_link_color': false,
    'inline_feedback_color': false,
  }

  static values = {
    sessionKey: String,
    showPreview: Boolean,
    isBasic: Boolean,
    previewPath: String,
    previewControlsPath: String,
    imageCdn: String
  }

  static targets = ['usePrimaryInterfaceColorRadios']

  connect() {
    this.editor = null
    this.setRemoveUploadLinks()
    this.addErrorSectionOverlays()
    this.setHandlers()
    this.toggleColorControl()

    if (this.showPreviewValue) {
      const previews = FollowUpEmailPreview.init({
        previewPath: this.previewPathValue,
        previewControlsPath: this.previewControlsPathValue
      })

      previews.showPreviewFromSession(this.sessionKeyValue);
    }
  }

  disconnect() {
    super.disconnect();
    this.editor.removeAllListeners();
  }

  setHandlers() {
    const that = this

    // update the current box being drawn on the image when the ckeditor is in focus
    CKEDITOR.on('instanceReady', function (evt) {
      that.editor = evt.editor;

      that.editor.on('focus', function() {
        that.addCurrentSectionOverlay($(this.element), false);
      });

    });

    // update the current box being drawn on the image when any input other than the ckeditor is in focus
    $("form :input").each(function () {
      $(this).focus(function() {
        that.addCurrentSectionOverlay($(this), false);
      });
    });

    $(".custom-file-upload .filename").each(function () {
      $(this).hover(function() {
        that.addCurrentSectionOverlay($(this), false);
      }, function() {
        that.removeCurrentSectionOverlay();
      });
    });

    // when an image is uploaded, take action
    $('.custom-file-upload input[type="file"]').on('change', function(data) {
      that.uploadActions($(this), data)
    });

    $('.preview-email').click(this.saveForPreview);
  }

  setRemoveUploadLinks() {
    const that = this;

    // for all text inputs inside of a custom-file-upload div, if it has a value, add the remove link
    $(".custom-file-upload .filename").each(function() {
      if ($(this).text() !== '') {
        that.addRemoveLink($(this).parent());
      }
    });
  }

  addCurrentSectionOverlay(element, isError) {
    // remove all overlays for the image currently being displayed
    this.removeCurrentSectionOverlay();

    const className = isError ? "current-section-error" : "current-section";

    // create the overlay and add it to the image
    if (element.attr("data_y") !== undefined) {
      $("<div/>",{
        "class" : className,
        "css" : {
          "top": parseInt(element.attr("data_y")),
          "left": parseInt(element.attr("data_x")),
          "width": parseInt(element.attr("data_w")),
          "height": parseInt(element.attr("data_h"))
        }
      }).appendTo($('.image'));
    }
  }

  addErrorSectionOverlays() {
    const that = this;

    $('.has-error input, .has-error .filename, .has-error textarea').each(function() {
      that.addCurrentSectionOverlay($(this), true);
    });
  }

  removeCurrentSectionOverlay() {
    $('.current-section').remove();
  }

  uploadActions(element, data) {
    if (data.target.files.length > 0) {
      // set the text input to the filename
      element.siblings(".filename").text(data.target.files[0]['name']);

      this.addRemoveLink(element.parent());
    }
  }

  addRemoveLink(element) {
    const that = this;
    // create the remove link
    var removeLink = $('<a>',{
      text: 'Remove image',
      href: '#'
    });

    // create the dive the remove link will sit inside of
    var removeLinkDiv =  $('<div>',{
      "class" : 'remove-upload'
    });

    removeLinkDiv.append(removeLink);

    // add the remove link to the file upload div
    element.append(removeLinkDiv);

    // when the remove image link is clicked, do the remove image actions
    $('.remove-upload a').click(function() {
      that.removeUploadActions($(this));
    });
  }

  removeUploadActions(element) {
    // remove the value of the file, filename and the value of the file id if there is one
    element.parent().parent().children('input[type="file"]').val('');
    element.parent().parent().children('.filename').text('');
    element.parent().parent().children('input[type="hidden"]').val('');

    // remove the div that holds the remove link
    element.parent().remove();
  }

  saveForPreview(e) {
    e.preventDefault();

    var form = $('.follow-up-form');

    $('<input>').attr({
      type: 'hidden',
      name: 'for_preview',
      value: 'true'
    }).appendTo(form);

    form.submit();
  }

  toggleColorControl() {
    if ($(this.usePrimaryInterfaceColorRadiosTargets).filter(':checked').val() === 'false') {
      $('#custom_button_link_color_controls').removeClass('hide');
    } else {
      $('#custom_button_link_color_controls').addClass('hide');
    }
  }

  colorValidated({ detail: { isValid, fieldName } }) {
    this.colorValidation[fieldName] = isValid
  }

  colorUpdated({ detail: { newColor, fieldName } }) {
    if (fieldName === 'primary_interface_color') {
      this.updatePrimaryPreviews(newColor)
    } else {
      this.updateFeedbackPreviews(newColor)
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const $submit = $(':submit');
    const valid = this.colorValidation['primary_interface_color'] || controls.customButtonLinkColor.is(":hidden");

    $submit.val($submit.attr('data-disable-with')).prop('disabled', true);

    if (valid) {
      controls.form.off('submit', handleSubmit);
      controls.form.submit();
    } else {
      $submit.val($submit.attr('data-enable-with')).prop('disabled', false);
    }
  }
}
