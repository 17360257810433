import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['useDefaultRiskLevels', 'riskLevelsMapping']

  connect() {
    MaxCount.init()
    this.useDefaultRiskLevelsTarget.checked ? this.hideRiskLevelsMapping() : this.showRiskLevelsMapping()
  }

  hideRiskLevelsMapping() {
    this.riskLevelsMappingTarget.classList.add('hide')
  }

  showRiskLevelsMapping() {
    this.riskLevelsMappingTarget.classList.remove('hide')
  }
}
