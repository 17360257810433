import { Controller } from "@hotwired/stimulus"
import DropzoneCreator from '../../dropzone/dropzone_creator'

export default class extends Controller {
  colorValidation = {}

  static values = {
    dropzoneImages: Object,
    backgroundImageCustomUrl: String,
    imageUploadPath: String,
    context: String,
    productLineKey: String
  }

  static targets = [
    'corporateOverrideRadios',
    'applicationNameRadios',
    'organizationNameRadios',
    'linkColorRadios',
    'cardColorRadios',
    'cardTransparencyRadios',
    'primaryInterfaceColorBgClass',
    'primaryInterfaceColorClass',
    'arrowLeftClass',
    'textCheckmarkColorClass',
    'linkColorClass',
    'inlineFeedbackColorBgClass'
  ]

  connect() {
    if (this.contextValue === 'corporation') {
      this.toggleCorporateOverride()
    }

    this.dzCreator = new DropzoneCreator('#branding_logos_and_colors_form', {listenToForm: false})

    const dzOpts = {
      acceptedFiles: 'image/png,image/jpeg',
      dictInvalidFileType: 'Image must be of type PNG or JPEG.',
      url: this.imageUploadPathValue
    }

    Object.entries(this.dropzoneImagesValue).forEach(([name, attributes]) => this.createImageDropzone(name, attributes['url'], {
      ...dzOpts, ...attributes['opts'],
      paramName: name
    }, attributes['hidden_field_name']));
  }

  createImageDropzone(name, url, opts, hidden_field_name) {
    this.dzCreator.createInstance( `#${name}_dropzone`, `#customizations_${this.productLineKeyValue}_branding_logos_and_color_form_${hidden_field_name}`, url, opts)
  }
  colorValidated({ detail: { isValid, fieldName } }) {
    this.colorValidation[fieldName] = isValid
  }

  toggleCorporateOverride() {
    const selected = $(this.corporateOverrideRadiosTarget).filter(':checked').val()
    if (selected === 'true') {
      $('.corporate-override').removeClass('hide')
    } else {
      $('.corporate-override').addClass('hide')
    }
  }

  updatePreview(e) {
    const field = e['currentTarget']

    if (field.dataset.fieldName === 'primaryInterfaceColor') {
      const color = this.getColor('primary_interface_color')
      this.primaryInterfaceColorClassTargets.forEach(e => e.style['color'] = color)
      this.primaryInterfaceColorBgClassTargets.forEach(e => e.style['background-color'] = color)
      this.arrowLeftClassTargets.forEach(e => e.style['border-right-color'] = color)
    } else if (field.dataset.fieldName === 'textCheckmarkColor') {
      const color = this.getCheckedRadioValue(field) === 'light' ? '#FFFFFF' : '#333333'
      this.textCheckmarkColorClassTargets.forEach(e => e.style['color'] = color)
      // The checkmark is an '::after' pseudo element, so we can't set a style on it directly
      var styleEl = document.head.appendChild(document.createElement("style"))
      styleEl.innerText = `.checkbox-preview .square-inner:after {border-color: ${color} !important;}`
    } else if (field.dataset.fieldName === 'linkColor') {
      const color = this.getCheckedRadioValue(field) === 'true' ? this.getColor('primary_interface_color') : this.getColor('custom_link_color')
      this.linkColorClassTargets.forEach(e => e.style['color'] = color)
    } else if (field.dataset.fieldName === 'inlineFeedbackColor') {
      const color = this.getColor('inline_feedback_color')
      this.inlineFeedbackColorBgClassTargets.forEach(e => e.style['background-color'] = color)
    }
  }

  getColor(name) {
    return '#' + document.querySelector(`.color-input-section#${name}_controls input`).value
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()
    const $submit = $(':submit')

    $submit.val($submit.attr('data-disable-with')).prop('disabled', true)

    if (Object.values(this.colorValidation).reduce((allValid, valid) => allValid && valid, true)) {
      this.dzCreator.processDzs()
    } else {
      $submit.val($submit.attr('data-enable-with')).prop('disabled', false)
    }
  }

  getCheckedRadioValue(element) {
    return [...element.querySelectorAll('input[type="radio"]')].find(i=>i.checked).value
  }
}
