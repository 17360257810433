import { Controller } from '@hotwired/stimulus'
import NestedModal from "../../helpers/nested_modal";
import { showModal, hideModal } from "../../helpers/jquery_wrapper";
import { jsonPost, get } from "../../helpers/fetch_utils";

export default class extends Controller {
  static values = {
    deleteCtaPath: String,
    reorderCtaPath: String,
    newCtaPath: String,
    editCtaPath: String,
    newDataPointPath: String,
    saveCtaPath: String,
    showCtaRowPath: String,
    dataPointValuesPath: String,
  }

  static targets = [
    'ctaTable',
    'ctaRow',
    'showWithCtas',
    'hideWithCtas',
    'autosave',
    'modal',
    'modalBody',
    'dataPointsContainer',
    'dataPointButton',
    'form',
    'primaryResultRadio',
    'primaryResultFilter',
    'dataPointSortOrder',
    'dataPointDeleteLink',
    'dataPointSelect',
  ];

  connect() {
    this.nestedModal = new NestedModal();
    this.updateModuleDisplay();
  }

  ///////// STIMULUS ACTIONS /////////
  reorderCtaUp(e) {
    e.preventDefault();
    const tr = e.target.closest('tr');
    this.saveReorder('up', tr)
    tr.parentNode.insertBefore(tr, tr.previousElementSibling);
    this.toggleArrows();
  }

  reorderCtaDown(e) {
    e.preventDefault();
    const tr = e.target.closest('tr');
    this.saveReorder('down', tr)
    tr.parentNode.insertBefore(tr, tr.nextElementSibling.nextElementSibling);
    this.toggleArrows();
  }

  openNewCtaModal() {
    this.isNewCta = true;
    this.modalTarget.querySelector('.modal-title').textContent = 'Add Additional Call to Action'
    this.loadModal(this.newCtaPathValue);
  }

  openEditCtaModal(e) {
    this.isNewCta = false;
    this.rowBeingEdited = e.target.closest('tr');
    this.additionalCtaUseId = this.rowBeingEdited.dataset.ctaUseId;
    const url = `${this.editCtaPathValue}?additional_cta_use_id=${this.additionalCtaUseId}`;
    this.modalTarget.querySelector('.modal-title').textContent = 'Edit Additional Call to Action'
    this.loadModal(url);
  }

  stageCtaRemoval(e) {
    this.ctaToRemove = e.target.closest('tr')
  }

  deleteDataPoint(e) {
    e.target.closest('.hra-data-point-select').remove();
    this.updateDataPointButton();
    this.updateDataPointsSortOrder();
    this.updateDataPointDeleteLinks();
  }

  displayNestedModal(e) {
    this.nestedModal.handleModalButtonClick(e, this.modalTarget);
  }

  // Show/Hide Primary Results checkboxes based on checked radio value ("All" / "Select Specific Results")
  togglePrimaryResultFilter() {
    const value = this.primaryResultRadioTargets.find(t => t.checked).value;

    if (value === 'true') {
      this.primaryResultFilterTarget.classList.add('hide');
    } else {
      this.primaryResultFilterTarget.classList.remove('hide');
    }
  }

  openModal() {
    showModal(this.modalTarget);
  }

  closeModal() {
    hideModal(this.modalTarget);
  }

  // CtaMatrixController triggered a change that caused the cta uses to be duplicated,
  // which means we need to update the ids of the additional ctas as well.
  handleNewCtaUseIds(e) {
    this.remapCtas(e.detail.ctaMap, true);
  }


  ///////// AJAX CALLS ////////////
  async removeCta(e) {
    const body = {
      cta_use_id: this.ctaToRemove.dataset.ctaUseId,
    };

    const { duplicatedCtasMap } = await jsonPost(this.deleteCtaPathValue, body);

    this.remapCtas(duplicatedCtasMap);
    this.ctaToRemove.remove();
    this.updateModuleDisplay();
    this.showTimedAutosave();
  }

  async saveReorder(direction, tr) {
    const body = {
      cta_use_id: tr.dataset.ctaUseId,
      direction: direction,
    };

    const {duplicatedCtasMap} = await jsonPost(this.reorderCtaPathValue, body)
    this.remapCtas(duplicatedCtasMap);
    this.showTimedAutosave();
  }

  async loadModal(url) {
    const html = await get(url);
    this.modalBodyTarget.innerHTML = html;
    this.updateDataPointButton();
    this.togglePrimaryResultFilter();
    this.updateDataPointDeleteLinks();
    this.openModal();
  }

  async addDataPoint(e) {
    e.preventDefault();
    const ind = this.dataPointsContainerTarget.children.length;
    const html = await get(this.newDataPointPathValue, { ind: ind });
    const template = document.createElement('template');
    template.innerHTML = html;
    this.dataPointsContainerTarget.appendChild(template.content.firstChild.querySelector('.hra-data-point-select'));
    this.updateDataPointButton();
    this.updateDataPointsSortOrder();
    this.updateDataPointDeleteLinks();
  }

  async loadDataPointValues(e) {
    const params = {
      data_point_id: e.target.value,
      token: e.target.dataset.token
    };

    const html = await get(this.dataPointValuesPathValue, params);
    const template = document.createElement('template');
    template.innerHTML = html;
    const oldContainer = e.target.closest('.hra-data-point-select').querySelector('.values-container');
    const newContainer = template.content.firstChild.querySelector('.values-container');
    oldContainer.replaceWith(newContainer);
  }

  async fetchCtaRow(ctaUseId) {
    const html = await get(this.showCtaRowPathValue, { cta_use_id: ctaUseId})
    const template = document.createElement('template');
    template.innerHTML = html;
    this.insertCtaRow(template.content.firstChild);
  }

  submitForm() {
    const baseUrl = window.location.origin;
    const url = new URL(this.saveCtaPathValue, baseUrl);

    if (!this.isNewCta) {
      url.searchParams.set('additional_cta_use_id', this.additionalCtaUseId);
    }

    if (this.selectedCtaUseId !== undefined) {
      url.searchParams.set('selected_cta_id', this.selectedCtaUseId);
    }

    const formData = new FormData(this.formTarget);

    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      credentials: 'same-origin',
    })
      .then(this.handleFormResponse.bind(this))
      .then(this.handleFormSuccess.bind(this))
      .catch(this.handleFormError.bind(this));
  }


  ////// AJAX HELPERS ////////
  handleFormResponse(response) {
    if (response.ok) {
      return response.json();
    }

    const error = new Error('validation error');
    error.response = response;
    throw error;
  }

  handleFormSuccess({ ctaUseId, duplicatedCtasMap }) {
    // ctaUseId will be blank if form is submitted without changing anything
    if (ctaUseId) {
      this.remapCtas(duplicatedCtasMap);
      this.fetchCtaRow(ctaUseId);
    }

    this.closeModal();
    this.showTimedAutosave();
  }

  handleFormError(error) {
    // Show Validation
    error.response.text().then(html => {
      this.modalBodyTarget.innerHTML = html;
      this.togglePrimaryResultFilter();
      this.updateDataPointButton();
      this.modalTarget.scrollTop = 0;
    });
  }


  /////////////   DOM CONTROLS    ///////////
  updateModuleDisplay() {
    if (this.ctaRowTargets.length > 0) {
      this.showWithCtasTargets.forEach(t => t.classList.remove('hide'));
      this.hideWithCtasTargets.forEach(t => t.classList.add('hide'));
    } else {
      this.showWithCtasTargets.forEach(t => t.classList.add('hide'));
      this.hideWithCtasTargets.forEach(t => t.classList.remove('hide'));
    }

    this.toggleArrows();
  }

  // Shows "Autosaved" message for 5 seconds
  showTimedAutosave() {
    this.autosaveTargets.forEach(t => t.classList.remove('hidden'));
    setTimeout(() => {
      this.autosaveTargets.forEach(t => t.classList.add('hidden'));
    }, 5000)
  }

  toggleArrows() {
    const allCtas = Array.prototype.slice.call(this.ctaTableTarget.querySelectorAll('tr'));

    allCtas.forEach((cta, i) => {
      const upArrow = cta.querySelector('.move-use-up')
      const downArrow = cta.querySelector('.move-use-down')
      const divider = cta.querySelector('.divider')

      switch (i) {
        case 0:
          upArrow.classList.add('hide')
          divider.classList.add('hide')

          if (allCtas.length > 1) {
            downArrow.classList.remove('hide')
          } else {
            downArrow.classList.add('hide');
          }

          break;
        case allCtas.length - 1:
          downArrow.classList.add('hide')
          upArrow.classList.remove('hide')
          divider.classList.remove('hide')
          break;
        default:
          upArrow.classList.remove("hide")
          downArrow.classList.remove("hide")
          divider.classList.remove('hide')
      }
    });
  }

  // Hide "Add Data Point" button if 4 data points are shown, show otherwise
  updateDataPointButton() {
    if (this.dataPointsContainerTarget.children.length === 4) {
      this.dataPointButtonTarget.classList.add('hide');
    } else {
      this.dataPointButtonTarget.classList.remove('hide');
    }
  }

  updateDataPointDeleteLinks() {
    // Hide delete link if only one data point remains, otherwise show links
    if (this.dataPointDeleteLinkTargets.length === 1) {
      this.dataPointDeleteLinkTarget.classList.add('hide');
    } else {
      this.dataPointDeleteLinkTargets.forEach((link) => link.classList.remove('hide'));
    }
  }

  insertCtaRow(row) {
    if (this.isNewCta) {
      this.ctaTableTarget.querySelector('tbody').appendChild(row);
    } else {
      this.rowBeingEdited.replaceWith(row);
    }

    this.updateModuleDisplay();
  }

  // All of the call_to_action_uses records are duplicated when a pending customization is created.
  // Whenever that happens, we need to update the id values on the frontend to point to the newly created uses.
  remapCtas(ctaMap, skipDispatch) {
    if (!ctaMap) return;

    if (!skipDispatch) {
      this.dispatch('newCtaUseIds', {detail: {ctaMap}});
    }

    this.ctaRowTargets.forEach((row) => {
      row.dataset.ctaUseId = ctaMap[row.dataset.ctaUseId]
    });
  }

  updateDataPointsSortOrder() {
    this.dataPointSortOrderTargets.forEach((input, ind) => input.value = ind);
  }

  selectRadioButton(e) {
    e.preventDefault();

    const radioId = e.currentTarget.dataset.radioId;
    document.getElementById(radioId).checked = true;
    this.updateSelectedClass(radioId);

    const ctaId = e.currentTarget.dataset.id;
    this.selectedCtaUseId = ctaId;
  }

  updateSelectedClass(radioId) {
    this.ctaRowTargets.forEach(row => row.classList.remove('selected'));
    const selectedRow = document.querySelector(`tr[data-cta-id="${radioId.split('_')[2]}"]`);
    selectedRow.classList.add('selected');
  }
}
