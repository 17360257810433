import hljs from 'highlight.js/lib/core'
import 'highlight.js/styles/default.css'
import javascript from 'highlight.js/lib/languages/javascript'
import haml from 'highlight.js/lib/languages/haml'
import ruby from 'highlight.js/lib/languages/ruby'
import json from 'highlight.js/lib/languages/json'
import css from 'highlight.js/lib/languages/css'
import scss from 'highlight.js/lib/languages/scss'
import xml from 'highlight.js/lib/languages/xml'
import http from 'highlight.js/lib/languages/http'
hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('haml', haml)
hljs.registerLanguage('xml', xml)
hljs.registerLanguage('http', http)
hljs.registerLanguage('ruby', ruby)
hljs.registerLanguage('json', json)
hljs.registerLanguage('css', css)
hljs.registerLanguage('scss', scss)


export default hljs