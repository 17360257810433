import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'toggle',
    'menu'
  ]

  connect() {
    Popper.createPopper(this.toggleTarget, this.menuTarget, {
      placement: 'auto',
      strategy: 'fixed',
      disablePortal: true,
    });
  }
}
