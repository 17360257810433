function getPostParams(body) {
  const params = {
    method: 'POST',
    headers: {
      'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
    },
    credentials: 'same-origin',
    body: body instanceof FormData ? body : JSON.stringify(body),
  };

  if (!(body instanceof FormData)) {
    params.headers['Content-Type'] = 'application/json'
  }

  return params;
}

// For AJAX calls that post JSON. Expects JSON response as default, unless third argument is specified
export async function jsonPost(url, body, expectHTML) {
  return fetch(url, getPostParams(body))
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error status: ${response.statusText}`);
      }

      return expectHTML ? response.text() : response.json();
    })
}

export async function get(url, paramObject) {
  const params = new URLSearchParams(paramObject);
  const separator = url.indexOf('?') === -1 ? '?' : '&';
  const fullUrl = url + separator + params;

  return fetch(fullUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error status: ${response.statusText}`);
      }

      return response.text();
    });
}
