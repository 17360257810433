import { Controller } from "@hotwired/stimulus"
import DropzoneCreator from '../../dropzone/dropzone_creator'

export default class extends Controller {
  static targets = [
    "backgroundImageCustom", "contentMiddleImageData", "colorInput", "displayControl", "textHeadlineRadios",
    "splashPreview", "showTitleBarFields", "customTitleBarColor", "customTitleBarTextColor", "headerTextHeadline",
    "headerCustomTextField", "customCardColor", "cardTransparency", "customCardTextColor",
    "contentUpperCustomCardColor", "contentUpperLogo", "contentUpperTextHeadline", "contentUpperCustomTextField",
    "middleContentRadios", "contentMiddleLogo", "contentMiddleTextHeadline", "contentMiddleCustomTextField",
    "contentMiddleImageControls", "contentMiddleBullets", "contentMiddleBulletsRadios",
    "useHorizontalRuleDividerCustomColor", "horizontalRuleDividersCustomColor", "customButtonLinkColor",
    "customButtonTextColor", "iPhone", "formModule", "showTitleBar", "useDefaultTitleBarColor", "titleBarTextColor",
    "headerRadio", "backgroundRadio", "backgroundColor", "backgroundImageRadio", "upload", "useDefaultCardColor",
    "cardTextColor", "useContentUpperCardColor", "topContentRadio", "contentMiddleBulletField",
    "useHorizontalRuleDividerRadio", "useDefaultHorizontalRuleDividerColorRadio", "useDefaultButtonLinkColorRadio",
    "buttonSizeRadio", "buttonStyleRadio", "useDefaultButtonTextColorRadio", "headerLogo", "headerTextColor",
  ];

  static values = {
    primaryInterfaceColor: String,
    headerColor: String,
    useDefaultCardColor: String,
    customCardColor: String,
    backgroundImageCustomUrl: String,
    backgroundImageCustomUploadPath: String,
    contentMiddleImageUrl: String,
    contentMiddleImageUploadPath: String,
    productLineInitials: String,
  };

  connect() {
    this.initializeDropzone();
    this.toggleContentMiddleBullets();

    this.white = 'ffffff';
    this.black = '000000';
    this.startTextColor = '666666';
    this.borderColor = 'eeedeb';
    this.iFrameLoaded = false;
    this.previousPreviewMessage = {};
    this.updateLivePreview();

    window.onscroll = () => this.scrollPreview();
    this.scrollPreview();
  }

  displayControlTargetConnected(elem) {
    if (elem.checked) this.toggleDisplayControls(elem);
  }

  textHeadlineRadiosTargetConnected(elem) {
    if (elem.checked) this.toggleTextHeadline(elem);
  }

  topContentRadioTargetConnected(elem) {
    if (elem.checked) this.topContentDisplay(elem);
  }

  middleContentRadiosTargetConnected(elem) {
    if (elem.checked) this.middleContentDisplay(elem);
  }

  initializeDropzone() {
    let backgroundDzOpts = {
      maxFilesize: 10,
      thumbnailHeight: 356,
      thumbnailWidth: 535,
      acceptedFiles: 'image/png,image/jpeg',
      dictInvalidFileType: 'Image must be of type PNG or JPEG.'
    };

    let middleDzOpts = {
      maxFilesize: 5,
      thumbnailHeight: 282,
      thumbnailWidth: 457,
      acceptedFiles: 'image/png,image/jpeg',
      dictInvalidFileType: 'Image must be of type PNG or JPEG.'
    };

    this.dzCreator = new DropzoneCreator('#splash_screen_form', { listenToForm: false });

    this.dzCreator.createInstance(
      '#content_middle_image_dropzone',
      `#${this.contentMiddleImageDataTarget.id}`,
      this.contentMiddleImageUrlValue,
      _.assign(
        middleDzOpts,
        { url: this.contentMiddleImageUploadPathValue, paramName: 'content_middle_image_data' }
      )
    );

    this.setDzEventHandlers(this.dzCreator.instances[0], this.updateMiddleImage.bind(this));

    if (document.getElementById('background_image_controls')) {
      this.dzCreator.createInstance(
        '#background_image_custom_dropzone',
        `#${this.backgroundImageCustomTarget.id}`,
        this.backgroundImageCustomUrlValue,
        _.assign(
          backgroundDzOpts,
          { url: this.backgroundImageCustomUploadPathValue, paramName: 'background_image_custom' }
        )
      );

      this.setDzEventHandlers(this.dzCreator.instances[1], this.setBackgroundImage.bind(this));
    }
  }

  async setDzEventHandlers(instance, handler) {
    let dzInstance = await instance.waitForDzInstance();
    dzInstance.on('thumbnail', handler);
  }

  setBackgroundImage(file) {
    if (!this.hasSplashPreviewTarget) return;
    let message = {
      customBackgroundImage: file.dataURL
    };

    this.splashPreviewTarget.contentWindow.postMessage(message, "*")
  }

  updateMiddleImage(file) {
    if (!this.hasSplashPreviewTarget) return;
    let message = {
      contentMiddleImage: file.dataURL
    };

    this.splashPreviewTarget.contentWindow.postMessage(message, "*")
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let submit = e.target;
    submit.value = submit.dataset.disableWith;
    submit.disabled = true;
    this.dzCreator.processDzs();
  }

  toggleDisplayControlHandler(e) {
    this.toggleDisplayControls(e.target);
  }

  toggleDisplayControls(elem) {
    let displayElem = this[elem.dataset.displayTarget];
    if (elem.dataset.displayControlValue === elem.value) {
      displayElem.classList.remove('hide');
    } else {
      displayElem.classList.add('hide');
    }
  }

  toggleTextHeadlineHandler(e) {
    this.toggleTextHeadline(e.target);
  }

  toggleTextHeadline(elem) {
    let value = elem.value;
    let textFieldTarget = this[elem.dataset.textFieldTarget];

    if (value === 'true') {
      textFieldTarget.disabled = true;
      textFieldTarget.value = textFieldTarget.dataset.defaultHeadline;

      // reset character counter
      const event = new Event('change');
      textFieldTarget.dispatchEvent(event);
    } else {
      textFieldTarget.disabled = false;
    }
  }

  topContentDisplayHandler(e) {
    this.topContentDisplay(e.target);
  }

  topContentDisplay(elem) {
    if (elem.value === 'logo') {
      this.contentUpperLogoTarget.classList.remove('hide');
      this.contentUpperTextHeadlineTarget.classList.add('hide');
    } else if (elem.value === 'custom_text') {
      this.contentUpperLogoTarget.classList.add('hide');
      this.contentUpperTextHeadlineTarget.classList.remove('hide');
    }
  }

  middleContentDisplayHandler(e) {
    this.middleContentDisplay(e.target);
  }

  middleContentDisplay(elem) {
    // not all targets shared between medicom and healthaware versions of page
    let logoTarget = this.hasContentMiddleLogoTarget ? this.contentMiddleLogoTarget : null
    let headlineTarget = this.contentMiddleTextHeadlineTarget;
    let imageTarget = this.contentMiddleImageControlsTarget;
    let bulletTarget = this.hasContentMiddleBulletsTarget ? this.contentMiddleBulletsTarget : null;

    if (elem.value === 'logo') {
      logoTarget.classList.remove('hide');
      this.hideTargets([headlineTarget, imageTarget, bulletTarget]);
    } else if (elem.value === 'custom_text') {
      headlineTarget.classList.remove('hide');
      this.hideTargets([logoTarget, imageTarget, bulletTarget]);
    } else if (elem.value === 'image') {
      imageTarget.classList.remove('hide');
      this.hideTargets([logoTarget, headlineTarget, bulletTarget]);
    } else if (elem.value === 'bullets') {
      bulletTarget.classList.remove('hide');
      this.hideTargets([logoTarget, headlineTarget, imageTarget]);
    } else {
      this.hideTargets([logoTarget, headlineTarget, imageTarget, bulletTarget]);
    }
  }

  toggleContentMiddleBullets() {
    if (!this.hasContentMiddleBulletFieldTarget) return;

    let value = this.contentMiddleBulletsRadiosTargets.find(t => t.checked).value;
    this.contentMiddleBulletFieldTargets.forEach((bulletTarget) => {
      if (value === 'true') {
        bulletTarget.disabled = true;
        bulletTarget.value = bulletTarget.dataset.defaultText;
      } else {
        bulletTarget.disabled = false;
      }
    });
  }

  hideTargets(targets) {
    targets.forEach((target) => {
      if (!target) return;
      target.classList.add('hide');
    });
  }

  validateSingleColor(elem) {
    let isHidden = elem.classList.contains('hide');
    let hasError = elem.querySelector('.form-group').classList.contains('has-error');
    return isHidden || !hasError;
  }

  scrollPreview() {
    const windowScroll = window.pageYOffset;
    const iphoneHeight = this.iPhoneTarget.offsetHeight;
    const moduleHeight = this.formModuleTarget.offsetHeight;
    const validationVisible = !!this.formModuleTarget.querySelector('.error-message');
    let scrollStart = 422;

    if (validationVisible) {
      scrollStart = 475;
    }

    let topPosition = 30 + windowScroll - scrollStart;

    if (windowScroll > scrollStart) {
      // Hit the break point sooner so it doesn't overflow
      if(175 + topPosition + iphoneHeight > moduleHeight) {
        if (validationVisible) {
          topPosition = topPosition - ((topPosition + iphoneHeight) - moduleHeight) - 170;
        } else {
          topPosition = topPosition - ((topPosition + iphoneHeight) - moduleHeight) - 90;
        }
      }

      this.iPhoneTarget.style.top = topPosition + 'px'
    } else {
      this.iPhoneTarget.style.top = '30px'
    }
  }

  handleIframeLoad() {
    if (this.iFrameLoaded) return;

    this.previousPreviewMessage = {};
    this.iFrameLoaded = true;
    this.updateLivePreview();
  }

  formatColor(val) {
    if ( val[0] === "#" ) {
      return val.slice(1, val.length);
    } else {
      return val;
    }
  }

  validateColor(formattedVal) {
    return formattedVal != '' && /^([A-Fa-f0-9]{6})$/i.test(formattedVal);
  }

  getLivePreviewColorMessage(field, color) {
    const message = {};
    const formattedColor = this.formatColor(color);

    if (this.validateColor(formattedColor)) {
      message[field] = formattedColor;
    }

    return message;
  }

  getLivePreviewBackgroundMessage() {
    let background = '';
    let backgroundColor = '';
    let backgroundImage = '';
    let customBackgroundImage = '';
    const selected = this.backgroundRadioTargets.find(t => t.checked).value;

    if (selected === 'custom_color') {
      background = 'custom_color';
      backgroundColor = this.formatColor(this.backgroundColorTarget.querySelector('input').value);
    } else {
      background = 'image';
      backgroundImage = this.backgroundImageRadioTargets.find(t => t.checked).value;

      if (backgroundImage === 'upload') {
        const img = this.uploadTarget.querySelector('.dropzone .dz-image img');
        if (img) customBackgroundImage = img.src;
      }
    }

    if (background === 'image' || this.validateColor(backgroundColor)) {
      return { background, backgroundColor, backgroundImage, customBackgroundImage };
    }

    return {};
  }

  getTitleBarTextColorMessage() {
    let message = {};

    const titleBarTextColor = this.titleBarTextColorTargets.find(t => t.checked).value;
    const customTitleBarTextColor = this.customTitleBarTextColorTarget.querySelector('input').value;

    if (titleBarTextColor === 'light') {
      _.assign(message, this.getLivePreviewColorMessage('titleBarTextColor', this.white));
    } else if (titleBarTextColor === 'dark') {
      _.assign(message, this.getLivePreviewColorMessage('titleBarTextColor', this.black));
    } else {
      _.assign(message, this.getLivePreviewColorMessage('titleBarTextColor', customTitleBarTextColor));
    }

    return message;
  }

  updateLivePreview() {
    let message = {};
    const productLine = this.productLineInitialsValue;

    // Title bar
    message.showTitleBar = this.showTitleBarTargets.find(t => t.checked).value;
    const useDefaultTitleBarColor = this.useDefaultTitleBarColorTargets.find(t => t.checked).value;
    const customTitleBarColor = this.customTitleBarColorTarget.querySelector('input').value;
    const titleBarColor = useDefaultTitleBarColor === 'true' ? this.headerColorValue : customTitleBarColor;
    _.assign(message, this.getLivePreviewColorMessage('titleBarColor', titleBarColor));

    message.header = this.headerRadioTargets.find(t => t.checked).value;
    message.headerCustomText = message['header'] === 'custom_text' ? this.headerCustomTextFieldTarget.value : '';

    if (productLine === 'mh') {
      _.assign(message, this.getTitleBarTextColorMessage());
      // Background
      _.assign(message, this.getLivePreviewBackgroundMessage());
    } else {
      message.headerLogo = this.headerLogoTarget.value;
      message.headerTextColor = this.headerTextColorTarget.querySelector('input').value;
    }

    // Card
    let cardColor;
    if (productLine === 'mh') {
      const useDefaultCardColor = this.useDefaultCardColorTargets.find(t => t.checked).value;
      const customCardColor = this.customCardColorTarget.querySelector('input').value;
      cardColor = useDefaultCardColor === 'true' ? this.white : customCardColor;
      _.assign(message, this.getLivePreviewColorMessage('cardColor', cardColor));
    }

    const cardTextColor = this.cardTextColorTargets.find(t => t.checked).value;
    const customCardTextColor = this.customCardTextColorTarget.querySelector('input').value;

    if (cardTextColor === 'light') {
      _.assign(message, this.getLivePreviewColorMessage('cardTextColor', this.white));
    } else if (cardTextColor === 'dark') {
      _.assign(message, this.getLivePreviewColorMessage('cardTextColor', this.startTextColor));
    } else {
      _.assign(message, this.getLivePreviewColorMessage('cardTextColor', customCardTextColor));
    }

    const useContentUpperCardColor = this.useContentUpperCardColorTargets.find(t => t.checked).value;
    const contentUpperCustomCardColor = this.contentUpperCustomCardColorTarget.querySelector('input').value;
    const haCardColor = this.useDefaultCardColorValue === 'true' ? this.white : this.customCardColorValue;
    cardColor = productLine === 'mh' ? cardColor : haCardColor;
    const contentUpperCardColor = useContentUpperCardColor === 'true' ? cardColor : contentUpperCustomCardColor;

    _.assign(message, this.getLivePreviewColorMessage('contentUpperCardColor', contentUpperCardColor));

    message.contentUpper = this.topContentRadioTargets.find(t => t.checked).value;
    message.contentUpperCustomText = this.contentUpperCustomTextFieldTarget.value;

    if (productLine === 'ha') {
      message.contentUpperLogo = this.contentUpperLogoTarget.value;
    }

    message.contentMiddle = this.middleContentRadiosTargets.find(t => t.checked).value;
    message.contentMiddleCustomText = this.contentMiddleCustomTextFieldTarget.value;

    if (productLine == 'mh') {
      message.contentMiddleBulletsBullet1 = this.contentMiddleBulletFieldTargets[0].value;
      message.contentMiddleBulletsBullet2 = this.contentMiddleBulletFieldTargets[1].value;
      message.contentMiddleBulletsBullet3 = this.contentMiddleBulletFieldTargets[2].value;
    } else {
      message.contentMiddleLogo = this.contentMiddleLogoTarget.value;
    }

    message.useHorizontalRuleDividers = this.useHorizontalRuleDividerRadioTargets.find(t => t.checked).value;
    const useDefaultHorizontalRuleDividerColor = this.useDefaultHorizontalRuleDividerColorRadioTargets.find(t => t.checked).value;
    const horizontalRuleDividersCustomColor = this.horizontalRuleDividersCustomColorTarget.querySelector('input').value;
    const horizontalRuleDividersColor = useDefaultHorizontalRuleDividerColor === 'true' ? this.borderColor : horizontalRuleDividersCustomColor;
    _.assign(message, this.getLivePreviewColorMessage('horizontalRuleDividersColor', horizontalRuleDividersColor));

    // Buttons and Links
    const useDefaultButtonLinkColor = this.useDefaultButtonLinkColorRadioTargets.find(t => t.checked).value;
    const customButtonLinkColor = this.customButtonLinkColorTarget.querySelector('input').value;
    const buttonLinkColor = useDefaultButtonLinkColor === 'true' ? this.primaryInterfaceColorValue : customButtonLinkColor;
    _.assign(message, this.getLivePreviewColorMessage('buttonLinkColor', buttonLinkColor));

    message.buttonSize = this.buttonSizeRadioTargets.find(t => t.checked).value;
    message.buttonStyle = this.buttonStyleRadioTargets.find(t => t.checked).value;
    const useDefaultButtonTextColor = this.useDefaultButtonTextColorRadioTargets.find(t => t.checked).value;
    const customButtonTextColor = this.customButtonTextColorTarget.querySelector('input').value;
    const buttonTextColor = useDefaultButtonTextColor === 'true' ? this.white : customButtonTextColor;
    _.assign(message, this.getLivePreviewColorMessage('buttonTextColor', buttonTextColor));

    if (!_.isEqual(this.previousPreviewMessage, message)) {
      this.splashPreviewTarget.contentWindow.postMessage(message, "*");
      this.previousPreviewMessage = message;
    }
  }
}
