import { Controller } from "@hotwired/stimulus"
import NestedModal from '../../helpers/nested_modal'

export default class extends Controller {
  static targets = [ "autosave", "hasSubmittedQuestion", "previewField", "questionToDelete", "importSubmitted",
    "questionLink", "importQuestionLink", "addPageLink", "previewModalButton", "pageContainer", "pageTable",
    "questionRow", "newQuestionModalContainer", "newQuestionModal", "showModalButton", "pageSelection", "previewModal",
    "previewAlert", "previewTitle", "togglePreviewValidation", "deletePageWarning", "importAppSelect",
    "importQuestionRow", "importQuestionSelect", "importDataNameRow", "importDataNameField" ];

  static values = {
    addContentPath: String,
    hideImportQuestionLink: Boolean,
    getImportOptionsPath: String,
    submittedQuestionHasError: Boolean,
    importedQuestionHasError: Boolean,
    preview: Boolean,
    maxPerPage: Number,
    maxQuestions: Number,
    showAutosave: Boolean
  }

  connect() {
    this.nestedModal = new NestedModal();
    this.questionToDelete = null;

    this.showPreviewOnLoad();
    this.showQuestionModalOnLoad();
    this.setUpSortableTable();
    this.initQuestionLinks();
    this.initQuestionImport();
    this.initAutosaveDisplay();
  }

  showPreviewOnLoad() {
    if (!this.previewValue) return;
    this.showModalButtonTarget.click();
    this.previewModalButtonTarget.click();
    this.nestedModal.adjustModalStack(this.newQuestionModalTarget, this.previewModalTarget);
  }

  showQuestionModalOnLoad() {
    if (!this.submittedQuestionHasErrorValue) return;
    this.showModalButtonTarget.click();
  }

  setUpSortableTable() {
    $('.connected-fields').sortable({
      connectWith: '.connected-fields',
      items: 'table > tbody > *',
      placeholder: 'sortable-placeholder',
      cursor: 'grabbing',
      start: this.handleSortableStart.bind(this),
      stop: this.handleSortableStop.bind(this),
      axis: 'y'
    });
  }

  initQuestionLinks() {
    if (this.questionRowTargets.length === this.maxQuestionsValue) {
      this.questionLinkTargets.forEach(t => t.classList.add('hide'));
    }

    if (this.pageTableTargets.length === 0 || this.pageTableTargets.length === this.maxQuestionsValue) {
      this.addPageLinkTarget.classList.add('hide');
    }
  }

  initQuestionImport() {
    if (this.hideImportQuestionLinkValue) {
      this.importQuestionLinkTarget.classList.add('hide');
    } else if (this.importedQuestionHasErrorValue) {
      this.importQuestionRowTarget.classList.remove('hide');
      this.importDataNameRowTarget.classList.remove('hide');
      this.importQuestionLinkTarget.firstElementChild.click();
    } else {
      this.importQuestionRowTarget.classList.add('hide');
      this.importDataNameRowTarget.classList.add('hide');
    }
  }

  initAutosaveDisplay() {
    if (this.showAutosaveValue) {
      this.autosaveTargets.forEach(t => t.classList.remove('hide'));
    } else {
      this.autosaveTargets.forEach(t => t.classList.add('hide'));
    }
  }

  submitQuestion() {
    this.hasSubmittedQuestionTarget.value = true;
  }

  submitPreview() {
    this.previewFieldTarget.value = true;
  }

  submitDelete() {
    this.questionToDeleteTarget.value = this.questionToDelete;
  }

  submitImport() {
    this.importSubmittedTarget.value = true;
  }

  populateImportQuestionOptions() {
    if (this.importAppSelectTarget.value === '') {
      this.importQuestionRowTarget.classList.add('hide');
      this.importDataNameRowTarget.classList.add('hide');
      return;
    }

    let url = `${this.getImportOptionsPathValue}?application_import_id=${this.importAppSelectTarget.value}`;
    fetch(url)
      .then(response => { if (response.ok) return response.json(); })
      .then(json => {
        let optionsHTML = '<option selected value="">Select Question</option>';
        json.forEach(option => {
          optionsHTML += `<option value="${option.id}">${option.name}</option>`
        });
        this.importQuestionSelectTarget.innerHTML = optionsHTML;
        this.importQuestionRowTarget.classList.remove('hide');
        this.importDataNameRowTarget.classList.add('hide');
      });
  }

  populateImportDataName() {
    if (this.importQuestionSelectTarget.value === "") {
      this.importDataNameRowTarget.classList.add('hide');
      return;
    }

    // reset any error validations
    this.importDataNameRowTarget.classList.remove('has-error');
    this.importDataNameFieldTarget.closest('.form-group').classList.remove('has-error');
    let helpBlock = this.importDataNameRowTarget.querySelector('.help-block');
    if (helpBlock) helpBlock.remove();

    let url = `${this.getImportOptionsPathValue}?custom_question_id=${this.importQuestionSelectTarget.value}`;
    fetch(url)
      .then(response => { if (response.ok) return response.json(); })
      .then(json => {
        if (json.isFreeText) {
          this.importDataNameRowTarget.classList.add('hide');
          return;
        }

        if (json.duplicate) {
          this.importDataNameRowTarget.classList.add('has-error')
          let span = document.createElement('span');
          span.innerHTML = 'Data names must be unique';
          span.classList.add('help-block', 'has-error');
          this.importDataNameFieldTarget.closest('.form-group').append(span);
        }

        this.importDataNameFieldTarget.value = json.value;
        this.importDataNameRowTarget.classList.remove('hide');
        MaxCount.reposition();
      });
  }

  prepareForDelete(e) {
    this.questionToDelete = e.target.closest('.question-row').dataset.id;

    let page = e.target.closest('.connected-fields');
    if (parseInt(page.dataset.numQuestions) === 1) {
      this.deletePageWarningTarget.classList.remove('hide');
    } else {
      this.deletePageWarningTarget.classList.add('hide');
    }
  }

  populateModal(e) {
    e.preventDefault();
    this.newQuestionModalContainerTarget.innerHTML = '';
    let questionType = e.params.questionType;
    let url;

    if (questionType) {
      // get form for new question
      url = `${this.addContentPathValue}?add_to_modal=true&content_type=${questionType}`;
    } else {
      // get form to edit existing question
      let id = e.target.closest('.question-row').dataset.id;
      url = `${this.addContentPathValue}?add_to_modal=true&question_id=${id}`;
    }

    fetch(url)
      .then(response => { if (response.ok) return response.text(); })
      .then(html => {
        this.newQuestionModalContainerTarget.innerHTML = html;
        this.replaceNewPageOption();
        this.showModalButtonTarget.click();

        // The character counter for CkEditor text areas are initialized elsewhere, so this prevents two counts from being stacked on top of each other
        const maxCounts = $(this.newQuestionModalContainerTarget).find('[data-maxcount]').not('textarea');
        MaxCount.init(maxCounts);

        $('.tooltips').tooltip({ placement: 'auto', html: true });
      });
  }

  replaceNewPageOption() {
    if (!this.hasEmptyPage() || !this.hasPageSelectionTarget) return;
    let options = this.pageSelectionTarget.options;
    let newPageOption = options[options.length - 1]
    newPageOption.text = `Page ${this.pageTableTargets.length}`;
    this.pageSelectionTarget.value = newPageOption.value;
  }

  togglePreviewValidation(e) {
    e.preventDefault();
    let btnClasses = this.togglePreviewValidationTarget.classList
    let alertClasses = this.previewAlertTarget.classList;

    if (btnClasses.contains('hide-validation')) {
      alertClasses.remove('hide');
      btnClasses.remove('hide-validation');
      btnClasses.add('show-validation');
      this.togglePreviewValidationTarget.innerHTML = "Preview Default";
      this.previewTitleTarget.classList.add('red-text');
    } else {
      alertClasses.add('hide');
      btnClasses.add('hide-validation');
      btnClasses.remove('show-validation');
      this.togglePreviewValidationTarget.innerHTML = "Preview Validation";
      this.previewTitleTarget.classList.remove('red-text');
    }
  }

  displayNestedModal(e) {
    this.nestedModal.handleModalButtonClick(e, this.newQuestionModalTarget);
  }

  addBlankPage(e) {
    e.preventDefault();
    this.addPageLinkTarget.classList.add('hide');
    this.addPage();
  }

  addPage() {
    let newPageInd = this.pageTableTargets.length;
    let pageNode = this.pageTableTargets.at(0).cloneNode(true);
    pageNode.dataset.pageInd = `${newPageInd}`;
    pageNode.dataset.numQuestions = '0';
    pageNode.querySelector('.section-header').innerHTML = `Custom Questions Page ${newPageInd + 1}`;
    pageNode.querySelector('tbody').innerHTML = '<tr></tr>'; // empty tr needed so sortable doesn't mess with table widths
    this.pageContainerTarget.append(pageNode);
    this.setUpSortableTable();
  }

  handleSortableStart(ev, ui) {
    // placeholder needs to line up with rest of table to not mess with widths while dragging
    let html = '';
    [...Array(4)].forEach(_ => html += `<td></td>`);
    ui.placeholder.html(html);
  }

  handleSortableStop(ev, ui) {
    this.shiftQuestionsDown();
    let changed = this.updateSortOrders();
    if (changed) {
      this.updateAddPageLink();
      this.autosave();
    }
  }

  shiftQuestionsDown() {
    let overfilledPage = this.pageTableTargets.find(p => p.querySelectorAll('tr.question-row').length > this.maxPerPageValue)
    if (overfilledPage === undefined) return;
    let lastQuestion = overfilledPage.querySelector('tbody tr.question-row:last-child');

    if (!overfilledPage.nextElementSibling) {
      this.addPage();
    }

    let newTbody = overfilledPage.nextElementSibling.querySelector('tbody');
    newTbody.insertBefore(lastQuestion, newTbody.querySelector('tr.question-row'));

    // recursively shift questions down if next page is full too
    this.shiftQuestionsDown();
  }

  updateSortOrders() {
    let changed = false;
    let pageDeleted = false;

    this.pageTableTargets.forEach(page => {
      let pageInd = parseInt(page.dataset.pageInd);
      let questions = page.querySelectorAll('tr.question-row');
      let prevNumQuestions = parseInt(page.dataset.numQuestions);
      let newNumQuestions = questions.length;
      changed = changed || prevNumQuestions !== newNumQuestions;
      page.dataset.numQuestions = `${newNumQuestions}`;

      if (prevNumQuestions > 0 && newNumQuestions === 0) {
        page.remove();
        pageDeleted = true;
        return;
      }

      // Move questions one page up if needed
      if (pageDeleted) pageInd--;

      questions.forEach((q, i) => {
        let pageField = q.querySelector('.page-field');
        let sortOrderField = q.querySelector('.sort-order-field');

        changed = changed || parseInt(pageField.value) !== pageInd;
        changed = changed || parseInt(sortOrderField.value) !== i;

        pageField.value = pageInd;
        sortOrderField.value = i;
      });
    });

    if (pageDeleted) this.reorderPageIndexes();
    return changed;
  }

  reorderPageIndexes() {
    this.pageTableTargets.forEach((page, ind) => {
      page.dataset.pageInd = ind;
      page.querySelector('.section-header').innerHTML = `Custom Questions Page ${ind + 1}`;
    });
  }

  hasEmptyPage() {
    return this.pageTableTargets.find(t => t.querySelectorAll('tr.question-row').length === 0) !== undefined;
  }

  updateAddPageLink() {
    if (this.hasEmptyPage()) {
      this.addPageLinkTarget.classList.add('hide')
    } else if (this.pageTableTargets.length < this.maxQuestionsValue) {
      this.addPageLinkTarget.classList.remove('hide');
    }
  }

  autosave() {
    fetch(this.element.action, {
      method: "POST",
      body: new URLSearchParams(new FormData(this.element)),
    })
    .then(response => { if (response.ok) return response.json(); })
    .then(json => {
      if (!json) return;

      // The autosave can trigger a new pending customization to be created, so in that case we
      // need to update the id values on the page to reflect the newly duplicated question id values.
      json.forEach(idPair => {
        let row = this.questionRowTargets.find(t => t.dataset.id === idPair.old);
        row.dataset.id = idPair.new;
        row.querySelector("input[id$='custom_question_id']").value = idPair.new
      });
    });

    this.autosaveTargets.forEach(t => t.classList.remove('hide'));
  }
}
