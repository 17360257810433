import "dropzone/dist/dropzone.css";

export default class DropzoneInstance {
  async create(selector, formParamSelector, imageUrl, dzOpts, onSuccess, defaultImage) {
    this.selector = selector;
    this.defaultImage = defaultImage;

    const {default: Dropzone} = await import('dropzone')
    Dropzone.autoDiscover = false

    var defaultOpts, mergedOpts, $formParam, applyBaseStyles, that;
    that = this

    $formParam = $(formParamSelector)
    this.$formParam = $formParam
    defaultOpts = {
      addRemoveLinks: true,
      autoProcessQueue: false,
      autoDiscover: false,
      maxFiles: 1,
      paramName: 'image_upload',
      thumbnailHeight: 330,
      thumbnailWidth: 290,
      thumbnailMethod: 'contain',
      acceptedFiles: 'image/png,image/gif,image/jpeg',
      dictInvalidFileType: 'Image must be of type GIF, PNG, or JPEG.',
      dictRemoveFile: 'replace image',
      maxFilesize: 3,
      headers: {"X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')},
      error: function (file, errorMessage) {
        this.removeFile(file)

        // we want to hide any potential real errors from the server that we aren't expecting
        if (errorMessage.indexOf('Image must be of type') === -1 && errorMessage.indexOf('File is too big') === -1) {
          errorMessage = 'An error has occurred.'
        }
        $(this.element).find('.dz-message-inner').append('<div class="dz-image-error" style="margin-top: 20px; text-align: center; color: #DB3A1B;">' + errorMessage + '</div>')
      }
    }

    mergedOpts = _.assign(defaultOpts, dzOpts)
    this.mergedOpts = mergedOpts;

    this.dzInstance = new Dropzone(selector, mergedOpts)

    this.dzInstance.on("addedfile", function (file) {
      $(this.element).find('.dz-image-error').remove()
      $(selector + ' .dz-preview .dz-image').css({width: mergedOpts.thumbnailWidth, height: mergedOpts.thumbnailHeight})
      $(selector + '.dropzone-previews.dz-clickable').removeClass('has-error')
      $(selector + ' .dz-progress').css({display: 'none'})

      if (defaultImage && defaultImage.url !== file.dataURL) {
        that.addRestoreLink();
      }

      applyBaseStyles()
    })

    this.dzInstance.on('removedfile', function (file) {
      $formParam.val('')
      $(this.element).find('.dz-image-error').remove()
    })

    this.processQueue = function () {
      this.dzInstance.processQueue()
      $(selector + ' .dz-progress').css({display: 'inline'})
    }

    this.dzInstance.on('success', function (e, response) {
      $formParam.val(response.image_path)
      onSuccess(response)
    })

    applyBaseStyles = function () {
      $(selector + ' .dz-message').css({height: mergedOpts.thumbnailHeight})
      $(selector + '.dropzone-previews.dz-clickable').css({
        width: mergedOpts.thumbnailWidth,
        height: mergedOpts.thumbnailHeight,
      })
    }

    applyBaseStyles();

    if (imageUrl) {
      that.setImage(imageUrl);
      that.imageUrl = imageUrl;
    }
  }

  setImage(url) {
    var that = this;
    var mockFile = {
      name: 'currentImage',
      accepted: true,
      kind: 'image',
      dataURL: url
    };
    this.dzInstance.emit("addedfile", mockFile);
    this.dzInstance.files.push(mockFile);
    this.dzInstance.createThumbnailFromUrl(mockFile, this.mergedOpts.thumbnailWidth, this.mergedOpts.thumbnailHeight, 'contain', false, function (thumbnail) {
      that.dzInstance.emit('thumbnail', mockFile, thumbnail)
      that.dzInstance.emit("complete", mockFile);
    }, "anonymous");
  }

  addRestoreLink() {
    var removeLink = $(this.selector + ' .dz-remove');

    var restoreLink = $('<a>', {
      text: 'restore default',
      href: '#',
      class: 'dz-restore',
      click: this.restoreDefaultImage.bind(this),
    });

    $(restoreLink).insertAfter(removeLink);
  }

  restoreDefaultImage(e) {
    e.preventDefault();
    this.dzInstance.removeAllFiles();
    this.setImage(this.defaultImage.url);
    this.$formParam.val(this.defaultImage.path);
  }

  async waitForDzInstance() {
    // wait for create function to initialize dropzone instance
    const sleep = ms => new Promise(r => setTimeout(r, ms));
    while (!this.dzInstance) { await sleep(100); }
    return this.dzInstance;
  }
}
