import { Controller } from "@hotwired/stimulus"
import NestedModal from "../helpers/nested_modal";

export default class extends Controller {
  static values = {
    addLocationRowPath: String,
    addZipCodesRowPath: String,
    availableHras: Array,
  };

  static targets = [
    'routeByZips', 'zipCodesContainer', 'locationsContainer', 'locationRows', 'locationRow', 'locationSortOrder',
    'zipCodesRow', 'zipCodesRows', 'zipCodeHRASelect', 'zipCodeTextArea', 'hideZipCodeModal', 'zipCodeModalButton',
    'zipCodeModal', 'zipModalSubmitButton', 'zipCodesRowSortOrder', 'zipCodeTextAreaFormGroup',
    'zipCodeTextAreaHelpBlock', 'invalidZips', 'openZipCodeValidationModal'
  ];

  connect() {
    this.showSelectedRoutingSection();
    MaxCount.init();

    this.nestedModal = new NestedModal(this.zipCodeModalTarget);

    if (this.routeByZipsTarget.checked) {
      this.initZipCodesRows();
    }
  }

  inAvailableHras(id) {
    const ids = this.availableHrasValue.map((arr) => arr[1].toString());
    return ids.includes(id.toString());
  }

  // When rendering the location_row and zip_codes_rows partials, the bootstrap form tag is needed to set the fields correctly.
  // There can only be one form tag per page though, so the extra ones get removed here.
  removeFormTag(elem) {
    if (elem.closest('form') === elem.parentElement) {
      const form = elem.parentElement;
      form.outerHTML = form.innerHTML;
    }
  }

  initLocationRows() {
    this.locationRowTargets.forEach(row => this.removeFormTag(row));

    // Add blank location row if none are present
    if (this.locationRowTargets.length === 0) this.addLocationRow();
  }

  initZipCodesRows() {
    this.zipCodesRowTargets.forEach(row => this.removeFormTag(row));
  }

  showSelectedRoutingSection() {
    if (this.routeByZipsTarget.checked) {
      this.zipCodesContainerTarget.classList.remove('hide');
      this.locationsContainerTarget.classList.add('hide');
    } else {
      this.initLocationRows();
      this.zipCodesContainerTarget.classList.add('hide');
      this.locationsContainerTarget.classList.remove('hide');
    }
  }

  post(url, body, callback) {
    const requestParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    };

    fetch(url, requestParams)
      .then(response => { if (response.ok) return response.text(); })
      .then(html => {
        callback(html)
      });
  }

  addLocationRow(e) {
    if (e) e.preventDefault();

    const body = {
      hraOptions: this.availableHrasValue,
      sortOrder: this.locationRowTargets.length,
    }

    this.post(this.addLocationRowPathValue, body, (html) => {
      const parser = new DOMParser();
      const form = parser.parseFromString(html, 'text/html');
      const row = form.querySelector('.row');
      this.locationRowsTarget.appendChild(row);
      MaxCount.init();
    });
  }

  setRowToRemove(e) {
    this.rowToRemove = e.target.closest('.row');
  }

  removeRow() {
    this.rowToRemove.remove();
    this.rowToRemove = null;
  }

  deleteLocationRow() {
    // Remove row and update sort order values
    this.removeRow();
    this.locationSortOrderTargets.forEach((input, ind) => input.value = ind);
  }

  deleteZipCodesRow() {
    // Remove row and update sort order values
    this.removeRow();
    this.zipCodesRowSortOrderTargets.forEach((input, ind) => input.value = ind);
  }

  openZipCodeModal() {
    if (!this.openIsForEdit) {
      this.zipCodeTextAreaTarget.value = '';
      this.zipCodeModalTarget.querySelector('.modal-title').innerHTML = 'Add ZIP Code Mapping';
      this.zipModalSubmitButtonTarget.innerHTML = 'Save';
      this.isNewZipCodeRow = true;
    }
  }

  submitZipCodesModal(e) {
    e.preventDefault();

    if (this.isNewZipCodeRow) {
      this.addZipCodesRow();
    } else {
      this.editZipCodesRow();
    }
  }

  clearZipCodeValidation() {
    this.zipCodeTextAreaFormGroupTarget.classList.remove('has-error');
    this.zipCodeTextAreaHelpBlockTarget.classList.add('hide');
  }

  validateZipCodeModal() {
    this.clearZipCodeValidation();

    // Only allow application ids that are accessible to the client
    if (!this.inAvailableHras(this.zipCodeHRASelectTarget.value)) {
      return false;
    }

    // Strip whitespace and split by comma
    let zipArr = this.zipCodeTextAreaTarget.value.replace(/\s+/g, '').split(',');

    // Only 5 digit zips are allowed, but we can truncate any zips that are in ZIP+4 format (ex: 12345-1234)
    zipArr = zipArr.map((zip) => {
      if (zip.match(/^\d{5}-\d+$/) !== null) {
        return zip.substring(0, zip.indexOf('-'));
      }

      return zip;
    });

    // Remove duplicates and empty entries
    zipArr = [...new Set(zipArr)].filter(zip => zip !== '');

    // If nothing was entered, show normal text-box validation instead of opening modal
    if (zipArr.length === 0) {
      this.zipCodeTextAreaFormGroupTarget.classList.add('has-error');
      this.zipCodeTextAreaHelpBlockTarget.classList.remove('hide');
      return false;
    }

    // Find invalid zips
    const invalids = zipArr.filter(zip => zip.match(/^\d{5}$/) === null);

    if (invalids.length === 0) {
      this.sanitizedZipArray = zipArr;
      return true;
    }

    // Sort invalid zips so that any with non-numeric characters are shown first, then sort numerically after that
    invalids.sort((a, b) => {
      const aIsNumeric = a.match(/^\d+$/) !== null;
      const bIsNumeric = b.match(/^\d+$/) !== null;

      if (!aIsNumeric && bIsNumeric) {
        return -1;
      } else if (aIsNumeric && !bIsNumeric) {
        return 1;
      } else if (aIsNumeric && bIsNumeric) {
        return parseInt(a) - parseInt(b);
      } else {
        return a.localeCompare(b);
      }
    });

    // Display validation modal
    this.invalidZipsTarget.innerHTML = invalids.join(', ');
    this.nestedModal.displayNestedModal(this.openZipCodeValidationModalTarget, this.zipCodeModalTarget);
    return false;
  }

  addZipCodesRow(e) {
    const isValid = this.validateZipCodeModal();
    if (!isValid) return;

    const sortOrder = this.hasZipCodesRowTarget ? this.zipCodesRowTargets.length : 0;

    const body = {
      sortOrder,
      applicationId: this.zipCodeHRASelectTarget.value,
      zipCodes: this.sanitizedZipArray
    };

    this.post(this.addZipCodesRowPathValue, body, (html) => {
      const parser = new DOMParser();
      const form = parser.parseFromString(html, 'text/html');
      const row = form.querySelector('.row');
      this.zipCodesRowsTarget.appendChild(row);
      this.zipCodeTextAreaTarget.value = '';
      this.hideZipCodeModalTarget.click();
    });
  }

  openZipCodesEditModal(e) {
    e.preventDefault();
    const row = e.target.closest('.row');
    const sortOrder = row.querySelector('.sort-order-field').value;

    // Populate modal with existing HRA and ZIP codes
    this.zipCodeHRASelectTarget.value = row.querySelector('.application-id-field').value;
    this.zipCodeTextAreaTarget.value = row.querySelector('.zips-col').innerHTML.trim();

    // Open modal and edit title
    this.openIsForEdit = true;
    this.zipCodeModalButtonTarget.click();
    this.zipCodeModalTarget.querySelector('.modal-title').innerHTML = 'Edit ZIP Code Mapping';
    this.zipModalSubmitButtonTarget.innerHTML = 'Update Mapping';
    this.isNewZipCodeRow = false;
    this.editingRowInd = sortOrder;
    this.openIsForEdit = false;
  }

  editZipCodesRow() {
    const isValid = this.validateZipCodeModal();
    if (!isValid) return;

    const row = this.zipCodesRowTargets[this.editingRowInd];

    const newAppId = this.zipCodeHRASelectTarget.value;
    const hraText = this.zipCodeHRASelectTarget.selectedOptions[0].text;

    // Update the row with new values
    row.querySelector('.application-id-field').value = newAppId;
    row.querySelector('.zip-codes-field').value = this.sanitizedZipArray.join(' ');
    row.querySelector('.hra-name').innerHTML = hraText;
    row.querySelector('.zips-col').innerHTML = this.sanitizedZipArray.join(', ');

    this.hideZipCodeModalTarget.click();
  }

  handleUploadModalClick(e) {
    this.nestedModal.handleModalButtonClick(e, this.zipCodeModalTarget);
  }

  addZipCodesFromCSV(e) {
    this.clearZipCodeValidation();

    const zipsFromUpload = e.detail.content;
    const existingZips = this.zipCodeTextAreaTarget.value.split(',').map(zip => zip.trim()).filter(zip => zip !== '');

    // Combine existing zips with new ones and remove duplicates
    const allZips = [... new Set(existingZips.concat(zipsFromUpload))];

    this.zipCodeTextAreaTarget.value = allZips.join(', ');
  }
}
