import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "customCode", "toggleCustomCodeRadio" ]
  static classes = [ "hide" ]

  connect() {
    this.toggleCustomCodeFields()
  }

  toggleCustomCodeFields() {
    const display = this.toggleCustomCodeRadioTargets.find(customCodeRadio => customCodeRadio.checked).value === 'true'

    if (display) {
      this.customCodeTarget.classList.remove(this.hideClass)
    } else {
      this.customCodeTarget.classList.add(this.hideClass)
    }
  }
}
