import { Controller } from '@hotwired/stimulus'
import { showModal, hideModal } from "../../helpers/jquery_wrapper";
import { get, jsonPost } from "../../helpers/fetch_utils";

export default class extends Controller {
  static values = {
    followUpCustomizationId: Number,
    togglePcpPath: String,
    showPath: String,
    editPath: String,
    updatePath: String,
    selectPath: String,
    isRemarketing: Boolean,
  };

  static targets = [
    'emailPreview', 'disablePcpModal', 'enablePcpModal', 'toggleSwitch', 'pcpHeaderAny', 'pcpHeaderYes',
    'leftColumnCell', 'rightColumnCell', 'autosave', 'selectModal', 'setEditForm', 'emailCheckbox', 'useEditRow'
  ];

  connect() {
    this.initializeTooltips();
  }

  initializeTooltips() {
    $('.tooltips').tooltip({ placement: 'auto', html: true });
  }

  handleToggleSwitch(e) {
    if (this.checkForUnsavedChanges(e)) {
      // Revert toggle switch and return
      e.target.checked = !e.target.checked;
      return;
    }

    const numEmails = this.emailPreviewTargets.length;

    if (numEmails === 0) {
      this.togglePcp(e.target.checked);
      return;
    }

    // Revert toggle switch and display confirmation modal
    e.target.checked = !e.target.checked;

    if (e.target.checked) {
      showModal(this.disablePcpModalTarget);
    } else {
      showModal(this.enablePcpModalTarget);
    }
  }

  confirmPcpDisable() {
    this.toggleSwitchTarget.checked = false;
    this.togglePcp(false);
  }

  confirmPcpEnable() {
    this.toggleSwitchTarget.checked = true;
    this.togglePcp(true);
  }

  showTimedAutosave() {
    this.autosaveTargets.forEach(t => t.classList.remove('hidden'));

    setTimeout(() => {
      this.autosaveTargets.forEach(t => t.classList.add('hidden'));
    }, 5000)
  }

  togglePcp(enablePcp) {
    const body = {
      id: this.followUpCustomizationIdValue,
      display_pcp: enablePcp,
    };

    jsonPost(this.togglePcpPathValue, body)
      .then(response => {
        const leftSets = this.leftColumnCellTargets.map(cell => cell.querySelector('.follow-up-email-set'));
        this.followUpCustomizationIdValue = response.follow_up_customization_id;

        if (enablePcp) {
          this.pcpHeaderAnyTarget.classList.add('hide');
          this.pcpHeaderYesTarget.classList.remove('hide');

          // Copy over left cells to right cells and show
          this.rightColumnCellTargets.forEach(function(cell) {
            const rightSet = cell.querySelector('.follow-up-email-set');
            const leftSet = leftSets.find(set => set.dataset.followUpPlanId == rightSet.dataset.followUpPlanId);
            const clonedLeftSet = leftSet.cloneNode(true);

            leftSet.dataset.isLeftColumn = true;
            clonedLeftSet.dataset.isLeftColumn = false;

            rightSet.replaceWith(clonedLeftSet);
            cell.classList.remove('hide');
          });
        } else {
          this.pcpHeaderAnyTarget.classList.remove('hide');
          this.pcpHeaderYesTarget.classList.add('hide');
          leftSets.forEach(set => set.dataset.isLeftColumn = '');
          this.rightColumnCellTargets.forEach(cell => cell.classList.add('hide'));
        }

        this.showTimedAutosave();
      });
  }

  getSetInfo(set) {
    const setInfo = {
      follow_up_customization_id: this.followUpCustomizationIdValue,
    }

    if (this.isRemarketingValue) {
      setInfo.is_remarketing = true;
    } else {
      setInfo.follow_up_plan_id = set.dataset.followUpPlanId;
      setInfo.is_left_column = set.dataset.isLeftColumn;
    }

    return setInfo;
  }

  showSet(e) {
    e.preventDefault();

    const setContainer = e.target.closest('.plan-content');

    get(this.showPathValue, this.getSetInfo(setContainer.querySelector('.follow-up-email-set')))
      .then(response => setContainer.innerHTML = response);
  }

  editSet(e) {
    e.preventDefault();

    const setContainer = e.target.closest('.plan-content');

    get(this.editPathValue, this.getSetInfo(setContainer.querySelector('.follow-up-email-set')))
      .then(response => {
        setContainer.innerHTML = response;
        this.initializeTooltips();
      });
  }

  saveSet(form) {
    jsonPost(this.updatePathValue, new FormData(form))
      .then((data) => {
        let setContainer = form.closest('.plan-content');
        setContainer.innerHTML = data.content;

        // Highlight the first empty time box if it exists
        setContainer.querySelector('input[id*="schedule_time_quantity"]:not([value])')?.focus();

        if (data.saved) {
          this.showTimedAutosave();
        }

        // the customization id may have changed if we created a new draft
        if (data.follow_up_customization_id) {
          this.followUpCustomizationIdValue = data.follow_up_customization_id;
        }
      });
  }

  selectUse(e) {
    e.preventDefault();

    const link = e.target;
    const isChangingUse = link.classList.contains('change-use');
    this.currentSet = link.closest('.follow-up-email-set');

    if (isChangingUse) {
      this.currentUseInput = link.closest('tr').querySelector('.change-use-input');
    } else {
      this.currentUseInput = this.currentSet.querySelector('.add-uses-input');
    }

    // Single select modal for changing use, multi select when adding a new use
    get(this.selectPathValue, { ...this.getSetInfo(this.currentSet), is_multi_select: !isChangingUse })
      .then(response => {
        this.selectModalTarget.querySelector('.modal-title').textContent = 'Available Emails: ' + this.currentSet.dataset['title'];
        this.selectModalTarget.querySelector('.modal-body').innerHTML = response;

        if (isChangingUse) {
          this.selectModalTarget.querySelector('.btn-submit').classList.add('hidden');
        } else {
          this.selectModalTarget.querySelector('.btn-submit').classList.remove('hidden');
          this.checkEmailCount();
        }

        showModal(this.selectModalTarget);
      });
  }

  addUse(e) {
    e.preventDefault();

    this.currentSet.querySelector('.save-input').value = 'false';
    this.currentUseInput.value = e.target.dataset.id;

    this.saveSet(this.currentSet.querySelector('form'));
    hideModal(this.selectModalTarget);
  }

  addUses(e) {
    e.preventDefault();

    this.currentSet.querySelector('.save-input').value = 'false';
    const checkedEmailIds = this.emailCheckboxTargets.filter(cb => cb.checked).map(cb => cb.value);
    this.currentUseInput.value = checkedEmailIds;

    this.saveSet(this.currentSet.querySelector('form'));
    hideModal(this.selectModalTarget);
  }

  removeUse(e) {
    e.preventDefault();

    const link = e.target;
    const form = link.closest('form');

    link.closest('tr').querySelector('.remove-use-input').value = 'true';
    form.querySelector('.save-input').value = 'false';

    this.saveSet(form);
  }

  submit(e) {
    e.preventDefault();
    this.saveSet(e.target);
  }

  toggleMoreEmails(e) {
    e.preventDefault();

    e.target.closest('.set-content').querySelectorAll('.toggle-content').forEach(element => {
      element.classList.contains('hidden') ? element.classList.remove('hidden') : element.classList.add('hidden')
    });
  }

  previewUse(e) {
    e.preventDefault();

    const emailId = e.target.dataset.id;

    hideModal(this.selectModalTarget);
    previews.showPreviewForEmailUseSelect(emailId);
  }

  backToAvailable() {
    showModal(this.selectModalTarget);
  }

  checkForUnsavedChanges(e) {
    if (this.hasSetEditFormTarget) {
      let action;

      if (e.type === 'input') {
        action = 'changing PCP Status';
      } else {
        e.preventDefault();
        action = 'leaving the page';
      }

      alert(`You have unsaved changes. Please save or discard any changes before ${action}.`);
    }

    return this.hasSetEditFormTarget;
  }

  checkEmailCount() {
    const selectedEmails = this.emailCheckboxTargets.filter(cb => cb.checked);
    const unselectedEmails = this.emailCheckboxTargets.filter(cb => !cb.checked);
    const addedEmails = this.useEditRowTargets.filter(row => this.currentSet === row.closest('.follow-up-email-set'));
    const numEmails = addedEmails.length + selectedEmails.length;

    if (numEmails >= 10) {
      unselectedEmails.forEach(cb => cb.disabled = true);
    } else {
      unselectedEmails.forEach(cb => cb.disabled = false);
    }
  }
}
