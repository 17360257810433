import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  tableData = [];
  leadsCapturedByData = [];
  requiredHeaders = [ 'url_tag', 'visited', 'started', 'leads_captured', 'completed', 'clicked_any_cta' ];
  chartColors = ['#44243B', '#8B1EA6', '#4282C6', '#DB3A31', '#EFAF58', '#5E8280', '#76B7B2', '#059669', '#44243B80', '#8B1EA680', '#4282C680', '#DB3A3180', '#EFAF5880', '#5E828080', '#76B7B280', '#05966980', '#44243B1A', '#8B1EA61A', '#4282C61A', '#DB3A311A', '#EFAF581A', '#5E82801A', '#76B7B21A', '#0596691A'];

  static values = {
    reportData: Object,
    reportType: String,
    hasLeadsCapturedData: Boolean,
    hideReportVisits: Boolean,
    width: Number,
  };
  static targets = [ 'basicBarChart' ];

  connect() {
    this.buildCharts();
  }

  buildCharts() {
    if (this.hideReportVisitsValue) {
      this.requiredHeaders = this.requiredHeaders.filter(header => header !== 'visited');
    }

    // first panel
    const graphUIData = this.buildGraphUI();

    if (this.hasBasicBarChartTarget) {
      this.basicBarChartTarget.dataset.basicBarChartDataValue = JSON.stringify(graphUIData);
    }

    // second panel
    this.tableData = this.buildTableData();
    this.buildTableUI(this.tableData);

    // third panel
    this.leadsCapturedByData = this.buildLeadsCapturedByData();
    this.buildLeadsCapturedByUI(this.leadsCapturedByData.categories, this.leadsCapturedByData.seriesData);
  }

  buildGraphUI() {
    let inputData = this.reportDataValue.application_traffic_source;
    const groups = inputData.map((a) => a.url_tag);

    const formattedData = [
      {
        name: 'HRA Visits',
        color: this.chartColors[0],
        data: inputData.map((d) => d.visited)
      },
      {
        name: 'Began HRA',
        color: this.chartColors[1],
        data: inputData.map((d) => d.started )
      },
      {
        name: 'Leads Captured',
        color: this.chartColors[2],
        data: inputData.map((d) => d.leads_captured)
      },
      {
        name: 'Completed HRA',
        color: this.chartColors[3],
        data: inputData.map((d) => d.completed)
      },
      {
        name: 'Clicked Any CTA',
        color: this.chartColors[4],
        data: inputData.map((d) => d.clicked_any_cta)
      },
    ];

    return {
      title: "",
      xAxis: groups,
      yAxis: {
        title: {
          enabled: false
        }
      },
      seriesData: formattedData
    }
  }

  buildTableData() {
    let tableData = this.reportDataValue.application_traffic_source.map((val) => {
      let tableRow = {};
      this.requiredHeaders.forEach((header) => {
        if (val[header] !== undefined && val[header] !== null) {
          if (header === 'url_tag' || header === 'visited') {
            tableRow[header] = val[header];
          } else {
            tableRow[header] = val[header + "_formatted"];
          }
        } else {
          tableRow[header] = 0;
        }
      });
      return tableRow;
    });

    return tableData;
  }

  buildTableUI(data) {
    let headerEntries = {
      ['URL ' + this.reportTypeValue + ' Tag']: null,
      'HRA<br>Visits': this.chartColors[0],
      'Began<br>HRA': this.chartColors[1],
      'Leads<br>Captured': this.chartColors[2],
      'Completed<br>HRA': this.chartColors[3],
      'Clicked<br>Any CTA': this.chartColors[4],
    };
    if (this.hideReportVisitsValue) {
      headerEntries = Object.fromEntries(
        Object.entries(headerEntries).filter(([key, value]) => key !== 'HRA<br>Visits')
      );
    }

    const headers = Object.keys(headerEntries).map((info) => {
      if (headerEntries[info]) {
        return '<div class="table-chart-container">' +
          '<div class="table-chart-legend" style="color:' + headerEntries[info] + ' !important">&#9632;</div>' +
          '<span class="table-chart-legend-title">' + info + '</span></div>';
      } else {
        return info;
      }
    });

    charts.setTableChart(
      false,
      data,
      'applications-table-chart',
      headers,
      true,
      null,
      true
    );
  }

  buildLeadsCapturedByData() {
    const reportTypeLowerCase = this.reportTypeValue.toLowerCase();

    const categories = Array.from(
      new Set(this.reportDataValue.leads_captured.map((val) => val[reportTypeLowerCase] )));
    let reportDataGroupedByDate = [];

    this.reportDataValue.leads_captured.forEach((el) => {
      const found = reportDataGroupedByDate.find((obj) => obj.name === el.date );
      if (!found) {
        let newData = { name: el.date };
        categories.forEach((cat) => {
          newData[cat] = 0;
        });
        reportDataGroupedByDate.push(newData);
      }
    });

    this.reportDataValue.leads_captured.forEach((el) => {
      let found = reportDataGroupedByDate.find((obj) => obj.name === el.date);
      found[el[reportTypeLowerCase]] = el.leads_captured;
    });

    return {
      categories: categories,
      seriesData: reportDataGroupedByDate
    };
  }

  buildLeadsCapturedByUI(categories, seriesData) {
    const baseOptions = {
      plotOptions: {
        column: {
          stacking: 'normal',
          maxPointWidth: 40
        }
      },
      legend: {
        symbolRadius: 0
      },
      colors: this.chartColors
    };

    if (seriesData && seriesData.length > 0) {
      charts.setColumnChart(
        seriesData,
        'leads-captured-column-chart',
        categories,
        'leads captured',
        this.widthValue,
        450,
        '11px',
        baseOptions
      );
    }
  }

  downloadTableCsv(event) {
    event.preventDefault();

    if (this.tableData) {
      aggregate_reports.exportReport('Traffic Source Table', this.tableData);
    }
  }

  downloadReportByGroupChartCsv(event) {
    event.preventDefault();

    aggregate_reports.exportReport(
      `Traffic ${Utils.capitalize(this.reportTypeValue)} Chart`,
      this.buildReportByGroupChartCsv()
    );
  }

  buildReportByGroupChartCsv() {
    const csv = this.reportDataValue.application_traffic_source.map((val) => {
      let csvRow = {};
      this.requiredHeaders.forEach((header) => {
        if (val[header] !== undefined && val[header] !== null) {
          csvRow[header] = val[header];
        } else {
          csvRow[header] = 0;
        }
      });
      return csvRow;
    });

    return csv;
  }

  downloadLeadsCapturedChartCsv(event) {
    event.preventDefault();

    if (this.hasLeadsCapturedDataValue) {
      aggregate_reports.exportReport(`Leads Captured by ${Utils.capitalize(this.reportTypeValue)}`, this.reportDataValue.leads_captured);
    } else {
      aggregate_reports.exportReport(`Leads Captured by ${Utils.capitalize(this.reportTypeValue)}`, []);
    }
  }
}
