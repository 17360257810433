import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'promptText', 'errorText', 'specificErrorText', 'firstInvalid', 'fileNameContainer',
    'fileName', 'fileInput', 'selectCsvButton', 'uploadCsvButton'
  ]

  handleSelectedFile(e) {
    this.promptTextTarget.classList.add('hide')
    this.fileNameTarget.innerText = e.target.files[0].name
    this.fileNameContainerTarget.classList.remove('hide')
    this.selectCsvButtonTarget.classList.add('hide')
    this.uploadCsvButtonTarget.classList.remove('hide')
    this.errorTextTarget.classList.add('hide')
    this.specificErrorTextTarget.classList.add('hide')
  }

  openFileSelect(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }

  uploadCsv(e) {
    e.preventDefault();
    const file = this.fileInputTarget.files[0]

    // Check file extension
    const fileName = file.name
    const fileExtension = fileName.split(".").pop().toLowerCase()

    if (fileExtension === "csv") {
      const reader = new FileReader()

      reader.onload = () => {
        const fileContent = reader.result
        const templateRows = [
          'Enter one ZIP code per row below, save this file as a CSV, and upload it back to the portal.',
          'zip_code',
        ];

        // Split rows into array, trim whitespace, and truncate any zips in ZIP+4 format to 5 digits instead.
        const rows = fileContent.replace(/[\r'"]/g, "")
          .split(/\n/)
          .filter(row => row.trim() !== '' && !templateRows.includes(row.trim()))
          .map(row => row.trim())
          .map((row) => {
            if (row.match(/^\d{5}-\d+$/) !== null) {
              return row.substring(0, row.indexOf('-'));
            }
            return row;
          });

        const firstInvalidRow = rows.find(row => row.trim().match(/^\d{5}$/) === null);

        if (firstInvalidRow) {
          this.fileNameContainerTarget.classList.add('hide')
          this.selectCsvButtonTarget.classList.remove('hide')
          this.uploadCsvButtonTarget.classList.add('hide')
          this.firstInvalidTarget.innerText = firstInvalidRow
          this.specificErrorTextTarget.classList.remove('hide')
          return
        }

        const uniqueValues = [...new Set(rows)];

        // Send zip codes over to main form controller for the page
        this.dispatch('upload', { detail: { content: uniqueValues } })
        this.clearUpload();
      }

      reader.readAsText(file)
    } else {
      this.promptTextTarget.classList.add('hide')
      this.fileNameContainerTarget.classList.add('hide')
      this.errorTextTarget.classList.remove('hide')
      this.selectCsvButtonTarget.classList.remove('hide')
      this.uploadCsvButtonTarget.classList.add('hide')
    }
  }

  clearUpload(e) {
    if (e) e.preventDefault();
    this.promptTextTarget.classList.remove('hide')
    this.fileNameTarget.innerText = ''
    this.fileNameContainerTarget.classList.add('hide')
    this.selectCsvButtonTarget.classList.remove('hide')
    this.uploadCsvButtonTarget.classList.add('hide')
    this.errorTextTarget.classList.add('hide')
    this.fileInputTarget.value = null;
    $('#upload_csv_modal').modal('toggle');
  }
}
