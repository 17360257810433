import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    saveCallbackRequestAttemptPath: String,
    requestId: String,
  }

  static targets = [
    'datePicker',
    'timePicker',
    'notes',
    'requestCode',
  ]

  connect() {
    this.setDatePicker();
  }

  setDatePicker() {
    $('[data-behaviour~=datepicker]').datepicker({
      autoclose: true
    });
  }

  saveForm() {
    const radioGroup = this.requestCodeTarget
    const radioButtons = radioGroup.querySelectorAll('input[type="radio"]');
    let selectedValue = null;
    radioButtons.forEach((radio) => {
      if (radio.checked) {
        selectedValue = radio.value;
      }
    });

    let request_data = {
      date: this.datePickerTarget.value,
      time: this.timePickerTarget.value,
      callback_result_code_id: selectedValue,
      notes: this.notesTarget.value,
      request: this.requestIdValue,
    }

    $.ajax({
      url: this.saveCallbackRequestAttemptPathValue,
      method: 'POST',
      data: request_data,
      success: () => {
        window.location.reload();
      },
      error: (response) => {
        document.querySelector('#log_phone_call_modal_wrapper').innerHTML = response.responseText
        document.querySelector('.modal-backdrop').remove()
        $('#log_phone_call').modal('toggle');
      }
    });
  }
}
