import { Controller } from '@hotwired/stimulus';
import { showModal } from "../../helpers/jquery_wrapper";

export default class extends Controller {
  static targets = [
    'applicationCheckbox',
    'riskLevelCheckbox',
    'applicationCheckboxesContainer',
    'applicationUses',
    'deleteWarning',
    'usesModal',
    'remarketingModal',
    'appsWithOptionalEmail'
  ];

  allCheckboxClicked(e) {
    if (e.target.checked)  {
      this.applicationCheckboxTargets.forEach(t => t.disabled = true);
      this.riskLevelCheckboxTargets.forEach(t => t.disabled = true);
      this.applicationCheckboxesContainerTargets.forEach(t => t.classList.add('hidden'))
    } else {
      this.applicationCheckboxTargets.forEach(t => { if (t.dataset.isInUse !== 'true') t.disabled = false });
      this.riskLevelCheckboxTargets.forEach(t => { if (t.dataset.isInUse !== 'true') t.disabled = false });
      this.applicationCheckboxesContainerTargets.forEach(t => t.classList.remove('hidden'))    }
  }

  applicationTypeCheckboxClicked(e) {
    const appTypeContainer = e.target.closest('.application-type-container');
    const riskLevelContainer = appTypeContainer.querySelector('.risk-level-container');

    if (e.target.checked && riskLevelContainer) {
      riskLevelContainer.classList.remove('hidden');
      riskLevelContainer.querySelectorAll('.risk-checkbox').forEach((checkbox) => {
        checkbox.disabled = false;
        checkbox.checked = true;
      });

    } else if (riskLevelContainer) {
      riskLevelContainer.classList.add('hidden');
      riskLevelContainer.querySelectorAll('.risk-checkbox').forEach((checkbox) => {
        checkbox.disabled = true;
        checkbox.checked = false;
      });
    }
  }

  riskLevelCheckboxClicked(e) {
    const appTypeContainer = e.target.closest('.application-type-container');

    if (!appTypeContainer.querySelector('.risk-level-container .risk-checkbox:checked')) {
      appTypeContainer.querySelector('.application-type-checkbox .application-checkbox:checked').checked = false;
      appTypeContainer.querySelector('.risk-level-container').classList.add('hidden');
      appTypeContainer.querySelectorAll('.risk-level-container .risk-checkbox').forEach((checkbox) => {
        checkbox.checked = false;
        checkbox.disabled = true;
      });
    }
  }

  showUsesModal(e) {
    e.preventDefault();
    const { emailName, appLinks } = e.target.dataset;
    this.deleteWarningTarget.classList.add('hide');
    this.usesModalTarget.querySelector('.modal-title').textContent = `Uses: ${emailName}`
    this.addApplicationUses(this.applicationUsesTarget, JSON.parse(appLinks));
    showModal(this.usesModalTarget);
  }

  addApplicationUses(target, appLinks) {
    target.innerHTML = '';

    appLinks.forEach((link) => {
      target.innerHTML += link + '<br/>'
    });
  }

  showRemarketingModal(e) {
    e.preventDefault();
    const appLinks = JSON.parse(e.target.dataset.appLinks);
    this.addApplicationUses(this.appsWithOptionalEmailTarget, appLinks);
    showModal(this.remarketingModalTarget);
  }
}
