import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'corporation',
    'sameAsClient',
  ];

  connect() {
    this.checkCorporationDropdown();
  }

  checkCorporationDropdown() {
    if (!this.hasCorporationTarget || !this.hasSameAsClientTarget) return;

    if (this.sameAsClientTarget.checked) {
      this.corporationTarget.disabled = true;
      this.corporationTarget.value = '';
    } else {
      this.corporationTarget.disabled = false;
    }
  }
}
