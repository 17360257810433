import { Controller } from '@hotwired/stimulus'
import { get } from '../../helpers/fetch_utils'
import { showModal} from "../../helpers/jquery_wrapper";

export default class extends Controller {
  static values = {
    redirectAppPath: String,
  };

  static targets = [
    'upgradeModalContainer',
    'allCheckbox',
    'appCheckbox',
    'errorBanner',
    'redirectModal',
    'fromName',
    'fromUrl',
    'toName',
    'toUrl',
    'redirectAppLink',
  ];

  async openUpgradeModal(e) {
    e.preventDefault();
    const html = await get(e.target.dataset.path);
    this.upgradeModalContainerTarget.innerHTML = html;
    showModal(this.upgradeModalContainerTarget.firstChild);
  }

  openRedirectModal(e){
    e.preventDefault();
    const { fromName, fromUrl, toName, toUrl } = e.target.dataset;

    this.fromNameTarget.textContent = fromName;
    this.fromUrlTarget.value = fromUrl;
    this.toNameTarget.textContent = toName;
    this.toUrlTarget.value = toUrl;
    const redirectUrl = `${this.redirectAppPathValue}?app_id=${e.target.dataset.id}`
    this.redirectAppLinkTarget.setAttribute('href', redirectUrl);
    showModal(this.redirectModalTarget);
  }

  allCheckboxClicked() {
    const valForApps = this.allCheckboxTarget.checked;
    this.appCheckboxTargets.forEach(t => t.checked = valForApps);
  }

  appCheckboxClicked() {
    this.allCheckboxTarget.checked = false;
  }

  submitForm(e) {
    if (!this.appCheckboxTargets.map(t => t.checked).includes(true)) {
      e.preventDefault();
      this.errorBannerTarget.classList.remove('hide');
    }
  }
}
