import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    addToModalPath: String,
    showModal: Boolean,
    displayAutosave: Boolean,
    orgName: String
  };

  static targets = [ "modalContainer", "showModalButton", "defaultCKEditorRadio", "defaultTextInputRadio",
    "conditionalVisibilityControl", "populateOrg", "autosave", "ckEditorWithDefault", "variableIntro",
    'myselfSelectedCKE', 'pageIntroCKE', 'myselfSelectedRadio', 'myselfSelectedDefault', 'pageIntroRadio',
    'pageIntroDefault'
  ];

  connect() {
    this.ckEditorsInitialized = 0;

    // We can't hide the CKeditor boxes before they are done initializing, otherwise their height properties don't get properly set
    if (!this.pendingConditionalVisibilityTargets) {
      this.pendingConditionalVisibilityTargets = [];
    }

    if (this.showModalValue) this.showModalButtonTarget.click();
    if (this.displayAutosaveValue) this.autosaveTargets.forEach(t => t.classList.remove('hide'));
  }

  pageIntroCKETargetConnected(elem) {
    this.oldIntroMaxCount = elem.dataset.defaultMaxcount;
  }

  pageIntroDefaultTargetConnected(elem) {
    this.oldIntroDefault = elem.dataset.altDefaultText;
  }

  defaultTextInputRadioTargetConnected(elem) {
    if (!elem.checked) return;
    this.toggleTextInput(elem);
  }

  conditionalVisibilityControlTargetConnected(elem) {
    if (!elem.checked) return;
    this.toggleConditionalVisibility(elem, true);
  }

  populateOrgTargetConnected(elem) {
    let text = elem.nextSibling.textContent;
    elem.nextSibling.textContent = text.replace('{ORGANIZATION_NAME}', this.orgNameValue);
  }

  populateModal(e) {
    e.preventDefault();
    let id = e.params.pageId;
    this.modalContainerTarget.innerHTML = '';
    let url = `${this.addToModalPathValue}?id=${id}`;

    fetch(url)
      .then(response => { if (response.ok) return response.text(); })
      .then(html => {
        this.modalContainerTarget.innerHTML = html;
        this.showModalButtonTarget.click();
      });
  }

  initializeCKEditor({ detail: { content }}) {
    if (this.ckEditorWithDefaultTargets.indexOf(content) === -1) return;

    let ckEditorFormGroup = content.closest('.form-group');
    let ckeInner = ckEditorFormGroup.querySelector('.cke_inner');
    let innerParent = ckeInner.parentElement;

    // Set up div that will overlay the editor when it's disabled
    innerParent.style.position = 'relative';
    innerParent.style.height = `${ckeInner.offsetHeight}px`;
    ckeInner.classList.add('cke-absolute-position');
    let overlay = document.createElement('div');
    overlay.classList.add('cke-absolute-position', 'cke-overlay');
    innerParent.insertBefore(overlay, null);

    let radioElem = ckEditorFormGroup.previousElementSibling.querySelector('input:checked');
    this.toggleCKEditor(radioElem, ckEditorFormGroup);
    this.ckEditorsInitialized += 1;

    // Once the last CKeditor is initialized, we can hide any targets that are waiting to be hidden
    if (this.ckEditorsInitialized === this.ckEditorWithDefaultTargets.length) {
      this.pendingConditionalVisibilityTargets.forEach(pendingDisplay => this.showHide(pendingDisplay, false));
      this.pendingConditionalVisibilityTargets = [];
      this.ckEditorsInitialized = 0;
    }
  }

  toggleCKEditorHandler(e) {
    let ckEditorFormGroup = e.target.closest('.form-group').nextElementSibling;
    this.toggleCKEditor(e.target, ckEditorFormGroup);
  }

  getDefaultIntroText(radioElem) {
    if (this.hasVariableIntroTarget) {
      return this.variableIntroTargets.find((t) => t.checked).dataset.defaultText;
    } else {
      return radioElem.dataset.defaultText;
    }
  }

  toggleCKEditor(radioElem, ckEditorFormGroup) {
    let value = radioElem.value;
    let ckId = ckEditorFormGroup.querySelector('textarea').id;
    let ckInstance = CKEDITOR.instances[ckId];
    let ckeInner = ckEditorFormGroup.querySelector('.cke_inner');
    let helpBlock = ckEditorFormGroup.querySelector('.help-block');
    let overlay = ckEditorFormGroup.querySelector('.cke-overlay');

    if (value === 'true') {
      overlay.classList.remove('hide');
      ckInstance.setReadOnly(true);
      ckInstance.setData(this.getDefaultIntroText(radioElem));

      if (ckEditorFormGroup.classList.contains('has-error')) {
        ckeInner.classList.remove('has-error');
        helpBlock.classList.add('hide');
      }
    } else {
      overlay.classList.add('hide');
      ckInstance.setReadOnly(false);
    }
  }

  toggleTextInputHandler(e) {
    this.toggleTextInput(e.target);
  }

  toggleTextInput(elem) {
    let value = elem.value;
    let input = document.getElementById(elem.dataset.inputId);

    if (value === 'true') {
      input.disabled = true;
      input.value = elem.dataset.defaultText;

      // reset character counter
      const event = new Event('change');
      input.dispatchEvent(event);
    } else {
      input.disabled = false;
    }
  }

  setCheckboxValues(e) {
    let target = e.target;
    let boxes = Array.from(target.closest('.form-group').querySelectorAll('input[type=checkbox]'));
    let noneBox = boxes.find(b => b.dataset.isNoneOption === 'true');
    let otherBoxes = boxes.filter(b => b.dataset.isNoneOption !== 'true');

    if (target.dataset.isNoneOption === 'true' && target.checked) {
      otherBoxes.forEach(b => b.checked = false);
    } else if (target.checked) {
      noneBox.checked = false;
    }
  }

  toggleConditionalVisibilityHandler(e) {
    this.toggleConditionalVisibility(e.target)
  }

  toggleConditionalVisibilityTwoHandler(e) {
    this.toggleConditionalVisibility(e.target)
  }

  showHide(element, show) {
    if (show) {
      element.classList.remove('hide');
    } else {
      element.classList.add('hide');
    }
  }

  toggleConditionalVisibility(elem, isPageLoad) {
    let value = elem.value;
    let displayTarget = document.getElementById(elem.dataset.displayTarget);

    // Don't hide targets containing CKeditors, we have to wait until those are done initializing before we can hide them
    if (isPageLoad && value !== 'true' && displayTarget.querySelector('.cke-editor') !== null) {
      // Sometimes these targets can be connected before the controller itself, so we would have to initialize the array here
      if (!this.pendingConditionalVisibilityTargets) {
        this.pendingConditionalVisibilityTargets = [];
      }

      this.pendingConditionalVisibilityTargets.push(displayTarget);
      return;
    }

    this.showHide(displayTarget, value === 'true');
  }

  toggleDefaultPageIntro(e) {
    let ckInstance = CKEDITOR.instances[this.ckEditorWithDefaultTarget.id];
    if (ckInstance.readOnly) {
      ckInstance.setData(e.target.dataset.defaultText);
    }
  }

  resetMaxCount(newMaxCount, ckTarget) {
    ckTarget.dataset.maxcount = newMaxCount;
    ckTarget.nextElementSibling.nextElementSibling.remove();
    $(ckTarget.nextElementSibling).data('maxcount-inited', false);
    MaxCount.init($(ckTarget));
  }

  changePageIntroDefault() {
    // Refresh text if default is selected, otherwise leave custom value alone
    if (this.pageIntroDefaultTarget.checked) {
      this.toggleCKEditor(this.pageIntroDefaultTarget, this.pageIntroCKETarget.closest('.form-group'));
    }
  }

  handleShowCaregiverFields() {
    this.pageIntroDefaultTarget.dataset.defaultText = this.oldIntroDefault;
    this.changePageIntroDefault();
    this.resetMaxCount(this.oldIntroMaxCount, this.pageIntroCKETarget);
  }

  handleHideCaregiverFields() {
    this.oldIntroDefault = this.pageIntroDefaultTarget.dataset.defaultText;
    this.oldIntroMaxCount = this.pageIntroCKETarget.dataset.maxcount
    this.pageIntroDefaultTarget.dataset.defaultText = this.myselfSelectedDefaultTarget.dataset.defaultText;
    this.changePageIntroDefault();
    this.resetMaxCount(this.myselfSelectedCKETarget.dataset.maxcount, this.pageIntroCKETarget);
  }
}
