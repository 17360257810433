import { Controller } from "@hotwired/stimulus"
import { showModal, hideModal } from "../../helpers/jquery_wrapper";

export default class extends Controller {
  static values = {
    createCallbackRequestAttemptPath: String,
    viewCallbackRequestAttemptsPath: String,
    changeResolutionPath: String,
    saveChangeResolutionPath: String,
    requestId: String,
    userId: String,
    applicationTypes: String,
  }

  static targets = [
    'requestCode',
    'logPhoneCallModalWrapper',
    'changeResolutionModalWrapper',
    'engagementQueueRow',
    'callbackRequestRow',
    'queueOption',
    'userFilterCheckbox',
    'hraFilter'
  ]

  connect() {
    this.updateHraFilter();
    this.updateQueueUsersFilter();
    this.updateRequestQueueFilter();
  }

  fetchLogPhoneCallForm(e) {
    e.preventDefault()
    $.ajax({
      url: this.createCallbackRequestAttemptPathValue,
      method: 'GET',
      data: {
        request: e.target.getAttribute('requestId')
      },
      success: (data) => {
        this.logPhoneCallModalWrapperTarget.innerHTML = data
        $('#log_phone_call').modal('toggle');
      }
    });
  }

  fetchPhoneCallLog(e) {
    e.preventDefault()
    $.ajax({
      url: this.viewCallbackRequestAttemptsPathValue,
      method: 'GET',
      data: {
        request: e.target.getAttribute('requestId')
      },
      success: function (data) {
        document.querySelector('#phone_call_log_modal_wrapper').innerHTML = data
        $('#phone_call_log').modal('toggle');
      }
    });
  }

  fetchActionLinks(e){
    e.preventDefault()
    this.fetchPhoneCallLog()
  }

  fetchChangeResolutionForm(e) {
    e.preventDefault()
    $.ajax({
      url: this.changeResolutionPathValue,
      method: 'GET',
      data: {
        request: e.target.getAttribute('requestId')
      },
      success: (data) => {
        this.changeResolutionModalWrapperTarget.innerHTML = data
        $('#change_resolution').modal('toggle');
      }
    });
  }

  handleResolutionSubmit(e){
    e.preventDefault()
    const radioButtons = this.requestCodeTarget.querySelectorAll('input[type="radio"]');
    let selectedValue = null;

    radioButtons.forEach((radio) => {
      if (radio.checked) {
        selectedValue = radio.dataset.isResultionCode;
      }
    });
    if (selectedValue == 'true'){
      this.saveChangeResolutionForm()
    } else {
      showModal('#change_table_alert')
    }
  }

  saveChangeResolutionForm(e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    const radioButtons = this.requestCodeTarget.querySelectorAll('input[type="radio"]');
    let selectedValue = null;

    radioButtons.forEach((radio) => {
      if (radio.checked) {
        selectedValue = radio.value;
      }
    });

    $.ajax({
      url: this.saveChangeResolutionPathValue,
      method: 'POST',
      data: {
        request: this.requestIdValue,
        callback_result_code_id: selectedValue
      },
      success: function () {
        window.location.reload()
      }
    });
  }

  updateHraFilter() {
    if (!this.hasHraFilterTarget) return;
    this.hraFilter = this.hraFilterTarget.value;
    this.applyEngagementQueueFilters();
  }

  updateQueueUsersFilter() {
    if (!this.hasUserFilterCheckboxTarget) return;
    this.queueUserFilter = this.userFilterCheckboxTarget.checked;
    this.applyEngagementQueueFilters();
  }

  applyEngagementQueueFilters() {
    this.engagementQueueRowTargets.forEach((row) => {
      const passesHraFilter = this.hraFilter === 'all' || row.dataset.hraFilter.includes(this.hraFilter);
      const passesQueueFilter = !this.queueUserFilter || row.dataset.isUsersQueue === 'true'

      row.style.display = passesHraFilter && passesQueueFilter ? 'table-row' : 'none';
    });
  }

  updateRequestQueueFilter() {
    if (!this.hasQueueOptionTarget) return;
    const queueFilter = this.queueOptionTargets.find(t => t.checked).value;

    this.callbackRequestRowTargets.forEach((row) => {
      if (queueFilter === 'all-requests' || row.dataset.hasBeenCalled === 'false') {
        row.style.display = 'table-row';
      } else {
        row.style.display = 'none';
      }
    });
  }
}
