import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    if (CKEDITOR.instances[this.element.id]) {
      CKEDITOR.instances[this.element.id].destroy()
    }
    CKEDITOR.replace(this.element.id, this.optionsValue);
    CKEDITOR.instances[this.element.id].on('instanceReady', () => this.onInstanceReady());
  }

  onInstanceReady() {
    this.dispatchConnectedEvent();
    this.applyErrorStyling();
    MaxCount.init($(this.element));
  }

  dispatchConnectedEvent() {
    this.dispatch('connected', { detail: { content: this.element } });
  }

  applyErrorStyling() {
    let formGroup = this.element.closest('.form-group');
    if (formGroup && formGroup.classList.contains('has-error')) {
      let ckeInner = formGroup.querySelector('.cke_inner');
      ckeInner.classList.add('has-error');
    }
  }
}
